import React, { useEffect, useState } from 'react';
import './SMSSettings.css';
import { NumericFormat } from 'react-number-format';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import * as u from "../utils/Common";
import axios from "axios";
import Swal from 'sweetalert2';


const SMSSettings = () => {
    const [settings, setSettings] = useState({ uname: '', password: '', mobileno: '', token: '' });
    const navigate = useNavigate();

    useEffect(() => {
        fetchSettings();
    }, [])

    const fetchSettings = async () => {
        const data = { userid: 'rsdata' };
        const url = `${process.env.REACT_APP_RAINBOW_URL}/smssettings_readbyuserid`;
        const response = await axios.post(url, data);
        if (response.data.length > 0) {
            setSettings({
                uname: response.data[0].username,
                password: response.data[0].password,
                mobileno: response.data[0].mobileno,
                token: response.data[0].token
            })
        }
    }

    const handleSettingsChange = (value, name) => {
        setSettings({ ...settings, [name]: value })
    }

    const handleSave = async () => {
        let value = settings.uname;
        if (!value.length > 0) {
            alert('Username Should Be Given');
            window.document.getElementById("uname").select();
            window.document.getElementById("uname").focus();
            return;
        }
        value = settings.password;
        if (!value.length > 0) {
            alert('Password Should Be Given');
            window.document.getElementById("password").select();
            window.document.getElementById("password").focus();
            return;
        }
        value = settings.mobileno;
        if (!value > 0) {
            alert('Mobile No. Should Be Given');
            window.document.getElementById("mobileno").select();
            window.document.getElementById("mobileno").focus();
            return;
        }
        value = settings.mobileno;
        if (String(value).length < 10 || String(value).length === 11) {
            alert('Please Check the Mobile No.');
            window.document.getElementById("mobileno").select();
            window.document.getElementById("mobileno").focus();
            return;
        }
        value = settings.token;
        if (!value.length > 0) {
            alert('Token Should Be Given');
            window.document.getElementById("token").select();
            window.document.getElementById("token").focus();
            return;
        }
        const data = {
            userid: 'rsdata',
            username: settings.uname,
            password: settings.password,
            mobileno: settings.mobileno,
            token: settings.token,
            doc: u.curdatetime(),
        }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/smssettings_create`
        const response = await axios.post(url, data);
        alert(response.data.message);
        navigate("/emptypage");
    }
    const handleRemove = async () => {
        const result = await Swal.fire({
            title: `Are you sure to Remove the User ${settings.uname}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        });

        if (result.isConfirmed) {
            const data = {
                userid: 'rsdata'
            }
            const url = `${process.env.REACT_APP_RAINBOW_URL}/smssettings_delete`
            const response = await axios.post(url, data);
            if (response.data.message === 'success') {
                alert("Username Removed Successfully!");
            }
            setSettings({ uname: '', password: '', mobileno: '', token: '' })
        }
    }
    const onEnterkeydown = (e, nextid) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (nextid === "save") {
                window.document.getElementById(nextid).focus();
                return;
            }
            window.document.getElementById(nextid).select();
            window.document.getElementById(nextid).focus();
        }

    }
    return (
        <div className='smssettings-divmain'>
            <div className='smssettings-container'>
                <div className='smssettings-top'>
                    <h4>SMS Settings</h4>
                    <AiOutlineClose onClick={() => navigate("/emptypage")} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "16px" }} />
                </div>
                <div className='smssettings-bottom'>

                    <div className='smssettings-inputgrp'>
                        <label htmlFor="uname">Username:</label>
                        <input type="text" id='uname' name='uname' autoComplete='off'
                            value={settings.uname} onChange={(e) => handleSettingsChange(e.target.value, 'uname')}
                            maxLength={25}
                            onKeyDown={(e) => onEnterkeydown(e, 'password')} />
                    </div>
                    <div className='smssettings-inputgrp'>
                        <label htmlFor="password">Password:</label>
                        <input type="password" id='password' name='password' autoComplete='off'
                            value={settings.password} onChange={(e) => handleSettingsChange(e.target.value, 'password')}
                            maxLength={25}
                            onKeyDown={(e) => onEnterkeydown(e, 'mobileno')} />
                    </div>
                    <div className='smssettings-inputgrp'>
                        <label htmlFor="mobileno">Mobile No.:</label>
                        <NumericFormat
                            id='mobileno'
                            autoComplete='off'
                            name='mobileno'
                            value={settings.mobileno}
                            onValueChange={(values) =>
                                handleSettingsChange(values.floatValue, "mobileno")
                            }
                            decimalScale={0}
                            thousandSeparator={false}
                            allowNegative={false}
                            allowLeadingZeros={false}
                            placeholder=""
                            isNumericString
                            maxLength={12}
                            onKeyDown={(e) => onEnterkeydown(e, 'token')} />
                    </div>

                    <div className='smssettings-inputgrp'>
                        <label htmlFor="token">Token:</label>
                        <input type="text" id='token' name='token' autoComplete='off'
                            value={settings.token} onChange={(e) => handleSettingsChange(e.target.value, 'token')}
                            maxLength={100}
                            onKeyDown={(e) => onEnterkeydown(e, 'save')} />
                    </div>

                    <div className='smssettings-btngrp'>
                        <button tabIndex={-1} className='test'> Test</button>
                        <button id='remove' onClick={handleRemove}> Remove</button>
                        <button id='save' onClick={handleSave}> Save</button>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default SMSSettings;