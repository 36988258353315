import "./transport.css"
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as u from "../utils/Common";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

const Transport = () => {
    const navigate = useNavigate();
    let url = '';

    const [formData, setFormData] = useState({
        transportgstin: '',
        transportid: '',
        transportname:'',
        mobile1 :'',
        mobile2 :'',
        mobile3 :'',
    });
    console.log(formData);
    const handleChange = (event) => {
        let { name, value } = event.target;
        if ((name==="transportgstin" || name==="transportid" ) && (value.length<=15)) {
            value=value.toUpperCase();
        } else {
            value=u.toProperCase(value)
        }
        setFormData({
            ...formData,
            [name]:value
        });
    };
    
    const curmode = useSelector(function (data) {
        return data.transportmode;
    })

    const transportnameRef = useRef('');
    const transportgstinRef = useRef('');

    useEffect(() => {
        transportnameRef.current.focus();
    }, []);

    const handleMobChange = (event) => {
        let { name, value } = event.target;
        if (!(/^\d*$/.test(value) && value.length <= 10)) {
            return;
        }
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        const fetchdata = async (e) => {
            const data = { transportcode : curmode[1] };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/transport_readbytransportcode`,data)
            if (response.data.length>0) {
                setFormData({
                    ...formData,
                    transportname:response.data[0].transportname,
                    transportgstin:response.data[0].transportgstin,
                    transportid:response.data[0].transportid,
                    mobile1:response.data[0].mobile1,
                    mobile2:response.data[0].mobile2,
                    mobile3:response.data[0].mobile3,
                });
                transportnameRef.current.focus();            
            }
        };
        if (curmode[0]==="Edit") {
            fetchdata();
        }
    },[curmode])

    const handleSave = async (e) => {
        let newValue = u.myTrim(formData.transportname);
        if (newValue === "") {
            alert("Name Should be Given");
            transportnameRef.current.focus();
            return;
        }
        newValue = u.myTrim(formData.transportgstin);
        if (newValue!=="")  {
            if (!u.validateGSTIN(newValue)) {
                alert("GSTIN not Valid format");
                transportgstinRef.current.focus();
                return;
            }
        }

        try {
            const data = {
                transportcode: (curmode[0] === "New" ? 0 : curmode[1]),
                transportname: u.myTrim(formData.transportname),
                transportgstin: u.myTrim(formData.transportgstin),
                transportid: u.myTrim(formData.transportid),
                mobile1: u.myTrim(formData.mobile1),
                mobile2: u.myTrim(formData.mobile2),
                mobile3: u.myTrim(formData.mobile3)
            };

            url = `${process.env.REACT_APP_RAINBOW_URL}/transport_create`
            if (curmode[0]==="Edit") {
              url = `${process.env.REACT_APP_RAINBOW_URL}/transport_update`
            }
            await axios.post(url, data );
 
            if (curmode[0]==="Edit") {
               navigate(-1)
               return;
            }
          } catch (error) {
            console.error('Error submitting form:', error);
         }

         reSetform();
         transportnameRef.current.focus();
     };

     const reSetform=()=> {
        transportnameRef.current.value='';
        formData.transportgstin= ''
        formData.transportid= ''
        formData.transportname=''
        formData.mobile1 =''
        formData.mobile2 =''
        formData.mobile3 =''
        setFormData(formData);
     }

    const defaultEnterRef = useRef(null)
    const defaultEscapeRef = useRef(null)

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
                defaultEnterRef.current.click();
            }
            if (event.key === 'Escape') {
                defaultEscapeRef.current.click();
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
     }, []);

    const formClose = ()=> {
        navigate(-1);
    }

    return (
        <>
            <div className="divmain" >
                <div className="frmtransport-main">
                    <div className="frmtransport-container-page">
                        <div className="frmtransport-header">
                            <h2>Transport</h2>
                        </div>
                        <div className="frmtransport-container">
                            <div className="frmtransport-panel">
                                <div className="frmtransport-group">
                                    <label for="transportname" className="frmtransport-label required">Transport Name</label>
                                    <input type="text" name="transportname" id="transportname" value={formData.transportname} onChange={handleChange} className="frmtransport-input" required autofocus maxlength="75" ref={transportnameRef} />
                                </div>

                                <div className="frmtransport-group">
                                    <label for="trasnportergstin" class="frmtransport-label">GSTIN</label>
                                    <div className="frmtransport-group-sub">
                                        <input type="text" 
                                            ref={transportgstinRef}
                                            className="input-field"
                                            id="transportgstin"
                                            maxLength={15}
                                            name="transportgstin"
                                            onChange={handleChange}
                                            value={formData.transportgstin}/>
                                        <label for="transporterid" id="frmtransport-label" className="transidlabel">Trans ID</label>
                                        <input type="text" 
                                            className="input-field"
                                            id="transportid"
                                            name="transportid"
                                            value={formData.transportid}
                                            onChange={handleChange} />                                       
                                    </div>
                                </div>

                                <div className="frmtransport-group">
                                    <label for="mobile number-1" className="frmtransport-label">Mobile</label>
                                    <div className="frmtransport-group-sub">
                                        <input type="text" id="frmtransport-mobile" name="mobile1"
                                            value={formData.mobile1}
                                            onChange={handleMobChange}
                                        />

                                        <input type="text" id="frmtransport-mobile" name="mobile2"
                                            value={formData.mobile2}
                                            onChange={handleMobChange}
                                        />

                                        <input type="text" id="frmtransport-mobile" name="mobile3"
                                            value={formData.mobile3}
                                            onChange={handleMobChange}
                                        />
                                    </div>
                                </div>

                                <div className="frmtransport-btngroup">
                                    <button className='frmtransport-btn' ref={defaultEscapeRef} onClick={() => formClose() }> Cancel</button>
                                    <button className='frmtransport-btn' ref={defaultEnterRef} onClick={() => handleSave()} >Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Transport;
