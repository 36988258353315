import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useDispatch } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText, AiOutlinePrinter, AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './AccLedgerLookup.css';

import { myaction } from '../../redux/Mystore';

const AccLedgerLookup = ({ accgroupid = 3 }) => {
    let groupid = accgroupid;
    let urlpath = "";

    const agentid = 5;
    const custid = 3;
    const salesid = 8;
    const taxid = 16;
    const supplyid = 4;

    let ispersonal = true;
    let formname = "Account Ledger";
    if (groupid === agentid) {
        formname = "Area Ledger";
        urlpath = "/accledger_agent"
    }
    if (groupid === custid) {
        formname = "Customer Ledger";
        urlpath = "/accledger_customer"
    }
    if (groupid === supplyid) {
        formname = "Supplier Ledger";
        urlpath = "/accledger_supplier"
    }
    if (groupid === salesid) {
        ispersonal = false;
        formname = "Sales Ledger";
        urlpath = "/accledger_sales"
    }
    if (groupid === taxid) {
        ispersonal = false;
        formname = "Tax Ledger";
        urlpath = "/accledger_tax"
    }
    const mydispatch = useDispatch()
    const navigate = useNavigate()
    const tableRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);
    const [search, setSearch] = useState('');
    const [sortField, setSortField] = useState('actname');
    const [sortOrder, setSortOrder] = useState('asc');

    const fetchdata = async () => {
        try {
            const data = { accgroupcode: groupid };
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/acchead_readgroupall`, data);
            console.log(response);
            setinitialData(response.data);
            setcurdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchdata();
    }, []);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value === "") {
            setcurdata(initialData);
            return;
        }
        const filtered = initialData.filter(item => item.actname.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);
    };

    const handleSort = (field) => {
        const order = sortField === field && sortOrder === 'asc' ? 'desc' : 'asc';
        setSortField(field);
        setSortOrder(order);
    };

    const handleDelete = (data) => {
        Swal.fire({
            title: 'Are you sure?',
            text: data.actname,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = {
            actcode: data.actcode,
        }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/acchead_delete`
        try {
            await axios.post(url, userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const thStyle = {
        position: 'sticky',
        top: '0',
        backgroundColor: '#222222',
    };

    const handlePrint = () => {
        window.print();
    };

    const handlePDFDownload = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#acchead-table' });
        doc.save('AccLedger-list.pdf');
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(initialData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "AccLedger");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const handleExportToJSON = () => {
        const blob = new Blob([JSON.stringify(initialData, null, 2)], { type: 'application/json' });
        saveAs(blob, 'data.json');
    };

    const handleEdit = (data) => {
        mydispatch(myaction.setaccledgermode(["Edit", data.actcode, data.accgroupcode]));
        navigate(urlpath)
    }

    const handleAddNew = () => {
        mydispatch(myaction.setaccledgermode(["New", 0, accgroupid]));
        navigate(urlpath)
    }

    const merge = (data1 = '', data2 = '', data3 = '') => {
        let str = data1
        if (data2 !== '') {
            str += (str === '' ? '' : ', ') + data2
        }
        if (data3 !== '') {
            str += (str === '' ? '' : ', ') + data3
        }
        return str;
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    return (
        <div id="frmlookupacchead" className='frmlookupacchead-divmain'>
            <div className="table-container">
                <h3>{formname}</h3>
                <div className='searchtext'>
                    <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <div className="mybtncontainer">
                        <button className="mybtn" onClick={handlePDFDownload}><AiOutlineFilePdf className="btn-icon" />PDF</button>
                        <button className="mybtn" onClick={handleExportToExcel}><AiOutlineFileExcel className="btn-icon" />Excel</button>
                        <button className="mybtn" onClick={handleExportToJSON}><AiOutlineFileText className="btn-icon" />JSON</button>
                        <button className="mybtn" onClick={handlePrint}><AiOutlinePrinter className="btn-icon" />Print</button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className='btn-icon' />Addnew</button>
                    </div>
                </div>

                <div className='lookupTable' >
                    <table className="tabledata" id="acchead-table" ref={tableRef}>
                        <thead className='tablehead' >
                            <tr style={{ position: 'sticky', top: '-1px' }}>
                                <th style={{ backgroundColor: "black" }} className="col-actname" >
                                    Name
                                </th>
                                {ispersonal &&
                                    <th style={{ backgroundColor: "black" }} className="col-place" >
                                        Place
                                    </th>
                                }
                                {ispersonal &&
                                    <th style={{ backgroundColor: "black" }} className="col-gstin">
                                        GSTIN
                                    </th>
                                }
                                {ispersonal &&
                                    <th style={{ backgroundColor: "black" }} className="col-mobile">
                                        Mobile
                                    </th>
                                }
                                <th style={{ backgroundColor: "black" }} className="col-action">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {curdata.map(data => (
                                <tr className="row-data" key={data.actcode} onDoubleClick={() => handleEdit(data)}>
                                    <td className="col-actname" >{data.actname}</td>
                                    {ispersonal &&
                                        <td className="col-place" >{data.place}</td>
                                    }
                                    {ispersonal &&
                                        <td className="col-gstin" >{data.gstin}</td>
                                    }
                                    {ispersonal &&
                                        <td className="col-mobile">
                                            <p>{merge(data.mobile1)}</p>

                                        </td>}
                                    <td className="col-action">
                                        <div className='col-action-div'>
                                            <button onClick={() => handleEdit(data)}>Edit</button>
                                            <button onClick={() => handleDelete(data)}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default AccLedgerLookup;
