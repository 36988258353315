import { useDispatch } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import './PriceListLookup.css';

import { myaction } from '../../redux/Mystore';

const PriceListLookup = () => {
    const mydispatch=useDispatch()
    const navigate=useNavigate()
    const tableRef = useRef();
    const [curdata, setcurdata] = useState([]);
    
    const fetchPriceListData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/pricelist_readall`);
            setcurdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };
    
    useEffect(() => {
        fetchPriceListData();
    },[]);

    const handleDelete = async(data) => {
        const urldata={ 
            pricelistno:data.pricelistno,
            pricelistname:data.pricelistname
        }
        const url=`${process.env.REACT_APP_RAINBOW_URL}/pricelist_delete`
        try {
            await axios.post(url,urldata);
            fetchPriceListData();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const thStyle = {
        position: 'sticky',
        top: '0',
        backgroundColor: '#222222',
    };

    const handleEdit = (data) => {
        console.log(data);
        mydispatch(myaction.setpricelistmode(["Edit",data.pricelistno]));
        navigate("/installpricelist")
    }

    const handleAddNew = () => {
        mydispatch(myaction.setpricelistmode(["New",0]));
        navigate("/installpricelist")
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div id="frmlookuppricelist" className='frmlookuppricelist-divmain'>
            <div className="table-container">
                <div className='searchtext'>
                    <h3>Price List</h3>
                    <div className="mybtncontainer">
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus/>Addnew</button>
                    </div>
                </div>
                <div className='lookupTable' >
                    <table className="tabledata" id="PriceList-table" ref={tableRef}>
                        <thead className='tablehead'  >
                            <tr style={{position: 'sticky', top: '-1px'}}>
                                <th style={{backgroundColor:"black"}}  className="col-pricelistname" >
                                    Name
                                </th>
                                <th style={{backgroundColor:"black"}} className="col-pricelistno">
                                    No.
                                </th>
                                <th style={{backgroundColor:"black"}} className="col-action" >Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {curdata.map(data => (
                                <tr className="row-data" key={data.PriceListNo} onDoubleClick={() => handleEdit(data)}>
                                    <td className="col-pricelistname" >{data.pricelistname}</td>
                                    <td className="col-pricelistno">{data.pricelistno}</td>
                                    <td className="col-action">
                                        <div className='col-action-div'>
                                        <button onClick={() => handleEdit(data)}>Edit</button>
                                        <button onClick={() => handleDelete(data)}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PriceListLookup;
