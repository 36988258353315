// npm install react-html-table-to-excel jspdf jspdf-autotable file-saver
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useDispatch } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText, AiOutlinePrinter,AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './PdtGroupLookup.css';

import { myaction } from '../../redux/Mystore';

const PdtGroupLookup = () => {
    
    const mydispatch=useDispatch()
    const navigate=useNavigate()
    const tableRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [pdtGroupdata, setpdtGroupdata] = useState([]);
    const [search, setSearch] = useState('');
    
    const fetchpdtGroupdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_readall`);
            setinitialData(response.data);
            setpdtGroupdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };
    
    useEffect(() => {
        fetchpdtGroupdata();
    },[]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value==="") { 
            setpdtGroupdata(initialData);    
            return;
        }
        const filtered = initialData.filter(item => item.pdtgroupname.toLowerCase().includes(value.toLowerCase()));
        setpdtGroupdata(filtered);
    };

    const handleDelete = async(group) => {
        const data={ pdtgroupcode : group.pdtgroupcode }
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_checkexistinpdtmstr`,data);
        if (response.data.length>0) {
            Swal.fire({
                text: 'Item Particulars are defined for this group ' + group.pdtgroupname,
                icon: 'error', // You can change this to 'success', 'error', 'warning', 'question'
                width: '300px'
            });
            return;
        }
            Swal.fire({
              title: 'Are you sure?',
              text: group.pdtgroupname,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                confirmDelete(group);
              }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = { 
            pdtgroupserial:data.pdtgroupserial,
            pdtgroupcode:data.pdtgroupcode,
            pdtgroupname:data.pdtgroupname
        }
        console.log(userdata)
        const url=`${process.env.REACT_APP_RAINBOW_URL}/productgroup_delete`
        try {
            await axios.post(url,userdata);
            fetchpdtGroupdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const handlePrint = () => {
        window.print();
    };
    
    const handlePDFDownload = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#pdtgroup-table' });
        doc.save('product-groups.pdf');
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(pdtGroupdata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Product Groups");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const handleExportToJSON = () => {
        const blob = new Blob([JSON.stringify(pdtGroupdata, null, 2)], { type: 'application/json' });
        saveAs(blob, 'data.json');
    };

    const handleEdit = (data) => {
        mydispatch(myaction.setpdtgroupmode(["Edit",data.pdtgroupcode]));
        navigate("/installproductgroup")
    }

    const handleAddNew = () => {
        mydispatch(myaction.setpdtgroupmode(["New",0]));
        navigate("/installproductgroup")
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    return (
        <div id="frmlookuppdtgroup" className='frmlookuppdtgroup-divmain'>
            <div className="table-container">
                <h3>Product Group</h3>
                <div className='searchtext'>
                    <input
                        type="text"
                        placeholder="Search by Name"
                        value={search}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <div className="mybtncontainer">
                        <button className="mybtn" onClick={handlePDFDownload}><AiOutlineFilePdf className="btn-icon" />PDF</button>
                        <button className="mybtn" onClick={handleExportToExcel}><AiOutlineFileExcel className="btn-icon" />Excel</button>
                        <button className="mybtn" onClick={handleExportToJSON}><AiOutlineFileText className="btn-icon" />JSON</button>
                        <button className="mybtn" onClick={handlePrint}><AiOutlinePrinter className="btn-icon"/>Print</button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus/>Addnew</button>
                    </div>

                </div>

                <div className='lookupTable' >
                    <table className="tabledata" id="pdtgroup-table" ref={tableRef}>
                        <thead className='tablehead'  >
                            <tr style={{position: 'sticky', top: '-1px'}}>
                                <th style={{backgroundColor:"black"}} 
                                    className="col-pdtgroupname">
                                    Group Name
                                </th>
                                <th style={{backgroundColor:"black"}} 
                                    className="col-pdtgroupserial">
                                    Serial
                                </th>
                                <th style={{backgroundColor:"black"}} 
                                    className="col-action" >
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {pdtGroupdata.map(group => (
                                <tr className="row-data" key={group.pdtgroupcode} onDoubleClick={() => handleEdit(group)}>
                                    <td className="col-pdtgroupname">{group.pdtgroupname}</td>
                                    <td className="col-pdtgroupserial">{group.pdtgroupserial}</td>
                                    <td className="col-action">
                                        <div className='col-action-div'>
                                            <button onClick={() => handleEdit(group)}>Edit</button>
                                            <button onClick={() => handleDelete(group)}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PdtGroupLookup;
