import React, { useState, useEffect, useRef } from 'react';
import './GSTR2B.css';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import JSZip from 'jszip';
import { AiOutlineClose } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { FaYoutube } from "react-icons/fa";
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import * as u from '../utils/Common';
import { useSelector } from 'react-redux';
import Marquee from 'react-fast-marquee';

const AgeGridForm = () => {

    const configArray = [{ id_name: "GSTR2B-Flash News", id_value: "" },
    { id_name: "GSTR2B-Youtube URL", id_value: "" }];

    const [formValue, setformValue] = useState(configArray)

    const updateData = (updates) => {
        const updatedData = configArray.map((item) => {
            const update = updates.find((u) => u.id_name === item.id_name);
            return update ? { ...item, id_value: update.id_value } : item;
        });
        setformValue(updatedData);
    };


    const fetchConfigValue = async () => {
        try {
            const data = { configArray }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
            if (response.data.length > 0) {
                updateData(response.data)
            }
        } catch (error) {
            console.error('Error fetching config value:', error);
        }
    };

    useEffect(() => {
        fetchConfigValue();
    }, []);

    const username = useSelector(function (data) {
        return data.gstr9username;
    })

    const [statesData, setStatesData] = useState([]);
    const fetchStatedata = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/api/states`);
            setStatesData(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };
    const [licensed, setlicensed] = useState('');
    const readlicense = async () => {
        try {
            const user = {
                username: username,
                email: 'REGISTER'
            }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readbyusername`, user);
            if (response.data.length > 0) {
                setlicensed(response.data[0].licensed_gstr2b);
                return;
            }
        }
        catch (error) {
            console.log("UserName Not found ...", error);
        }
    }

    useEffect(() => {
        fetchConfigValue();
        fetchStatedata();
        readlicense();
    }, []);


    let toolname = 'GSTR2B (DEMO)';
    if (licensed === 'Y' || username.toUpperCase() === 'ADMIN') {
        toolname = 'GSTR2B'
    }

    const navigate = useNavigate();

    const gridRef = useRef();
    const [gstin, setgstin] = useState('');
    const json2BInputRef = useRef();
    let dataobj = ['b2b', 'cdnr'];

    const [existingData, setExistingData] = useState([]);
    const [typeofdata, setTypeofData] = useState('b2b');
    const [filePrd, setFilePrd] = useState('');

    const [b2bcdnrcolumnDefs] = useState([
        {
            headerName: 'Srl.',
            field: 'num',
            editable: false, headerClass: 'custom-header', cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            flex: 0.5,

        },
        { headerName: 'CTIN', headerClass: 'custom-header', field: 'ctin', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        { headerName: 'Trade Name', headerClass: 'custom-header', field: 'trdnm', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.3 },
        {
            headerName: 'Bill No', headerClass: 'custom-header', field: 'inum', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 1.4,
            valueFormatter: (params) => {
                if (params.node.rowPinned) {
                    return "(" + String(params.value) + ")";
                }
            }
        },
        { headerName: 'Date', headerClass: 'custom-header', field: 'idt', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.8 },
        { headerName: 'Inv. Type', headerClass: 'custom-header', field: 'inv_typ', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.3 },
        { headerName: 'RCM', headerClass: 'custom-header', field: 'rev', editable: false, suppressMenu: true, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.3 },
        { headerName: 'TAX(%)', headerClass: 'custom-header', field: 'rt', editable: false, suppressMenu: true, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.3 },
        {
            headerName: 'Taxable Value', headerClass: 'custom-header', field: 'txval', editable: false, flex: 1, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'IGST', headerClass: 'custom-header', field: 'iamt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CGST', headerClass: 'custom-header', field: 'camt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'SGST', headerClass: 'custom-header', field: 'samt', editable: false, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        {
            headerName: 'CESS', headerClass: 'custom-header', field: 'csamt', editable: false, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' },
            valueFormatter: (params) => {
                const value = parseFloat(params.value);
                if (!isNaN(value)) {
                    return value.toFixed(2);
                } else {
                    return parseFloat(0.00);
                }
            }
        },
        { headerName: 'Source', headerClass: 'custom-header', field: 'source', editable: false, filter: true, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }, flex: 0.4 },
    ]);

    const addNum = (data) => {
        return data.map((item, index) => {
            return {
                ...item,
                num: index + 1,
            };
        });
    };
    const [colors, setColor] = useState(false);
    const [jsonData, setJsonData] = useState({});
    const [json2AData, setJson2AData] = useState({});
    const [gstrtwiseBreakUp, setGstRtWiseBreakUp] = useState(false);
    const [rtExists, setrtExists] = useState(false);
    const gstrtwiseperiod = "202410";

    const resetAll = () => {
        console.log("reset")
        setGstRtWiseBreakUp(false);
        setExistingData([]);
        setFilePrd('');
        setgstin('');
        setMonth2AArray([]);
        setrtExists(false);
        setUploadedFiles({});
        setUploaded(false);
    }

    const handleImport2BJson = async (event) => {
        const files = event.target.files[0];
        const fileName = files.name.toLowerCase();
        let parsedData = null;

        try {
            if (fileName.endsWith(".json")) {
                parsedData = await readJsonFile(files);
            } else if (fileName.endsWith(".zip")) {
                parsedData = await readJsonFromZip(files);
            } else {
                console.error("Unsupported file type. Please upload a .json or .zip file.");
                return;
            }
            if (parsedData) {
                resetAll();
                console.log(parsedData);
                setJsonData(parsedData.data.docdata);
                if (parsedData.data?.rtnprd) {
                    const date = parsedData.data.rtnprd;
                    const fp = date.substring(0, 2) + "/" + date.substring(2);
                    setFilePrd(fp);
                    const rtnperiod = date.substring(2) + date.substring(0, 2);
                    if (rtnperiod >= gstrtwiseperiod) {
                        setGstRtWiseBreakUp(true);
                        checkFilePrd(parsedData.data.docdata)
                    }
                }

                if (parsedData.data?.gstin) {
                    setgstin(parsedData.data.gstin);
                }
                setTypeofData("b2b");
                pushData(parsedData.data.docdata, "b2b");
                json2BInputRef.current.value = '';
            }

        } catch (error) {
            console.error("Error processing file:", error);
            json2BInputRef.current.value = '';
            return;
        }
        
    };
    const isEmpty = (data) => Object.keys(data).length === 0;
    let [Month2AArray, setMonth2AArray] = useState([]);
    const [gstr2AObj, setGSTR2AObj] = useState({});
    const pushData = (Data, types, isDisplay = true) => {

        if (isEmpty(Data)) {
            return;
        }
        const parsedData = Data;
        let mergedData = [];

        if (types === 'b2b' || types === 'b2ba') {
            const data = types === 'b2b' ? parsedData.b2b : parsedData.b2ba;
            if (!data) {
                if (isDisplay) {
                    alert('There is no Data');
                    setExistingData([]);
                }
                return;
            }
            else {
                if (gstrtwiseBreakUp) {
                    data.forEach((b2bItem) => {
                        b2bItem.inv.forEach((item) => {
                            const mergeData = { ...item, trdnm: b2bItem.trdnm, ctin: b2bItem.ctin, oinum: item.oinum, oidt: item.oidt, inum: item.inum, billval: item.val, idt: item.dt, inv_typ: item.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: item.srctyp ? item.srctyp : "--" }
                            mergedData.push(mergeData);
                        });
                    });
                }
                else {
                    data.forEach((b2bItem) => {
                        b2bItem.inv.forEach((invoice) => {
                            invoice.items.forEach((item) => {
                                const mergeData = { ...item, trdnm: b2bItem.trdnm, ctin: b2bItem.ctin, oinum: invoice.oinum, oidt: invoice.oidt, inum: invoice.inum, billval: invoice.val, idt: invoice.dt, inv_typ: invoice.typ, pos: invoice.pos, rev: invoice.rev, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: invoice.srctyp ? invoice.srctyp : "--" }
                                mergedData.push(mergeData);
                            });
                        });
                    });
                    if (!isDisplay) {
                        return mergedData
                    }
                }
            }
        }
        if (types === 'cdnr' || types === 'cdnra') {
            const data = types === 'cdnr' ? parsedData.cdnr : parsedData.cdnra;
            if (!data) {
                if (isDisplay) {
                    alert('There is no Data');
                    setExistingData([]);
                }
                return;
            }
            else {
                if (gstrtwiseBreakUp) {
                    data.forEach((cdnrItem) => {
                        cdnrItem.nt.forEach((item) => {
                            const mergeData = { ...item, trdnm: cdnrItem.trdnm, ctin: cdnrItem.ctin, oinum: item.ont_num, oidt: item.ont_dt, inum: item.ntnum, billval: item.val, idt: item.dt, inv_typ: item.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: item.srctyp ? item.srctyp : "--" }
                            mergedData.push(mergeData);
                        });
                    });
                }
                else {
                    data.forEach((cdnrItem) => {
                        cdnrItem.nt.forEach((nt) => {
                            nt.itms.forEach((item) => {
                                const mergeData = { ...item, trdnm: cdnrItem.trdnm, ctin: cdnrItem.ctin, oinum: nt.ont_num, oidt: nt.ont_dt, inum: item.ntnum, billval: item.val, idt: item.dt, inv_typ: item.typ, pos: nt.pos, rev: nt.rchrg, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: nt.srctyp ? nt.srctyp : "--" }
                                mergedData.push(mergeData);
                            });
                        });
                    });
                }
            }

        }
        if (isDisplay) {

            let updateddata = mergedData.map(({ cgst, igst, sgst, cess, ...rest }) => rest);
            const sortedData = sortedArray(updateddata);
            updateddata = addNum(sortedData);
            setExistingData(updateddata);
           console.log(updateddata)
        }
    }
    const sortedArray = (updateddata) => {
        return updateddata.sort((a, b) => {
            const dateA = new Date(a.idt.split('-').reverse().join("-"));
            const dateB = new Date(b.idt.split('-').reverse().join("-"));
            return dateA - dateB;
        })
    }
    const checkFilePrd = (Data) => {
        if (!Data) {
            return;
        }
        let dArray = [];
        console.log(Data);
        if (Data.b2b) {
            Data.b2b.forEach((b2bItem) => {
                b2bItem.inv.forEach((item) => {
                    const fp = item.dt.substring(3, 5) + " / " + item.dt.substring(6);
                    if (!dArray.includes(fp)) {
                        dArray.push(fp);
                    }
                    if (fp !== filePrd) {
                        setColor(true);
                    }
                });
            });
        }
        if (Data.cdnr) {
            Data.cdnr.forEach((b2bItem) => {
                b2bItem.nt.forEach((item) => {
                    const fp = item.dt.substring(3, 5) + " / " + item.dt.substring(6);
                    if (!dArray.includes(fp)) {
                        dArray.push(fp);
                    }
                    if (fp !== filePrd) {
                        setColor(true);
                    }
                });
            });
        }
        setMonth2AArray(dArray);
    }

    useEffect(() => {
        if (!rtExists) {
            pushData(jsonData, typeofdata);
        }
    }, [typeofdata, gstrtwiseBreakUp])

    useEffect(() => {
        if (rtExists) {
            mergeData(gstr2AObj, typeofdata);
        }
    }, [typeofdata, gstr2AObj])

    const read2AJson = async (files, month) => {
        const fileName = files.name.toLowerCase();
        let parsedData = null;

        try {
            if (fileName.endsWith(".json")) {
                parsedData = await readJsonFile(files);
            } else if (fileName.endsWith(".zip")) {
                parsedData = await readJsonFromZip(files);
            } else {
                console.error("Unsupported file type. Please upload a .json or .zip file.");
                return;
            }
            if (parsedData) {
                setJson2AData(parsedData);
                if (parsedData?.fp) {
                    const date = parsedData.fp;
                    const fp = date.substring(0, 2) + " / " + date.substring(2);
                    if (fp !== month) {
                        return;
                    }
                }
            }

        } catch (error) {
            console.error("Error processing file:", error);
            return;
        }
        return parsedData;
    };

    const handleDataChange = (event) => {
        const value = event.target.value;
        setTypeofData(value);
    }

    const calculateTotals = () => {
        let totalTaxableValue = 0, totalIGST = 0, totalCGST = 0, totalSGST = 0, totalCESS = 0;

        existingData.forEach((row) => {
            totalTaxableValue += isNaN(row.txval) ? 0 : row.txval;
            totalIGST += isNaN(row.iamt) ? 0 : row.iamt;
            totalCGST += isNaN(row.camt) ? 0 : row.camt;
            totalSGST += isNaN(row.samt) ? 0 : row.samt;
            totalCESS += isNaN(row.csamt) ? 0 : row.csamt;
        });

        return [
            {
                hsn_sc: 'Total-->',
                ctin: 'Total-->',
                qty: String(existingData.length),
                inum: String(existingData.length),
                statename: 'Total-->' + " " + "(" + String(existingData.length) + ")",
                txval: totalTaxableValue,
                iamt: totalIGST,
                camt: totalCGST,
                samt: totalSGST,
                csamt: totalCESS,
            }
        ];
    };

    const [pinnedBottomRowData, setPinnedBottomRowData] = useState([]);

    useEffect(() => {
        const totals = calculateTotals();
        setPinnedBottomRowData(totals);
    }, [existingData]);

    const readJsonFile = async (file) => {
        try {
            const content = await file.text();
            const jsonData = JSON.parse(content);
            return jsonData;
        } catch (error) {
            console.error("Error reading JSON file:", error);
            throw error;
        }
    };
    const readJsonFromZip = async (file) => {
        try {
            const zip = await JSZip.loadAsync(file);
            // Create an array of promises to handle each file in the ZIP
            const jsonPromises = Object.keys(zip.files).map(async (relativePath) => {
                const zipEntry = zip.files[relativePath];

                if (zipEntry.name.endsWith(".json")) {
                    const content = await zipEntry.async("string"); // Read the JSON file as text
                    const jsonData = JSON.parse(content); // Parse the content into JSON
                    return jsonData;
                }
            });

            // Wait for all promises to resolve and filter out undefined results
            const jsonFiles = (await Promise.all(jsonPromises)).filter(Boolean);

            if (jsonFiles.length === 0) {
                throw new Error("No JSON files found in the ZIP");
            }

            return jsonFiles.length === 1 ? jsonFiles[0] : jsonFiles;
        } catch (error) {
            console.error("Error reading ZIP file:", error);
            throw error;
        }
    };

    const getRowStyle = params => {
        if (params.node.rowPinned) {
            return { backgroundColor: "#ccc", fontWeight: "600" }
        }
        if (colors) {
            if(typeofdata === 'cdnr' || typeofdata === 'cdnra'){
                if(params.data.inv_typ === 'D'){
                    return { backgroundColor: "lightgreen" }
                }
            }
            if (params.data.rev === "Y") {
                return { backgroundColor: "darkgray" }
            }
            const fp = params.data.idt.substring(3, 5) + "/" + params.data.idt.substring(6);
            if (fp !== filePrd) {
                return { backgroundColor: "pink" }
            }
        }
    };
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState({});

    const toggleModal = () => {
        if (!existingData.length > 0) {
            alert("There is no Data in the Table");
            return;
        }
        setIsModalOpen(!isModalOpen);
    };

    const handleFileChange = async (e, month) => {
        const file = e.target.files[0];
        const result = await read2AJson(file, month);
        if (!result) {
            alert("This file not Belongs to this Period");
            return;
        }
        setUploadedFiles((prev) => ({
            ...prev,
            [month]: file.name,
        }));
        setGSTR2AObj((prev) => {
            // Merge `b2b` arrays and ensure no duplicates (if necessary)
            const mergedB2B = [...(prev?.b2b || []), ...(result.b2b || [])];
            const mergedB2BA = [...(prev?.b2ba || []), ...(result.b2ba || [])];
            const mergedCDNR = [...(prev?.cdnr || []), ...(result.cdn || [])];
            const mergedCDNRA = [...(prev?.cdnra || []), ...(result.cdna || [])];
            return {
                ...prev,
                gstin: result.gstin,
                fp: result.fp,
                b2b: mergedB2B,
                b2ba: mergedB2BA,
                cdnr: mergedCDNR,
                cdnra: mergedCDNRA,
            };
        });
    };
    const allFilesUploaded = Month2AArray.every((month) => uploadedFiles[month]);
    const [uploaded, setUploaded] = useState(false);
    const handleUploadOk = () => {
        if (!allFilesUploaded) {
            alert("Please Upload the Files");
            return;
        }
        alert("Files are Uploaded Successfully!");
        setIsModalOpen(false);
        mergeData(gstr2AObj, typeofdata);
        setUploaded(true);
        setUploadedFiles({});
    }

    const findInum = (types, b2bArray, targetInum, targetctin) => {
        if (types === 'b2b' || types === 'b2ba') {
            const matched = b2bArray.find(item => {
                return item.inv.some(invoice => invoice.inum === targetInum && item.ctin === targetctin)
            });
            return matched;
        }
        if (types === 'cdnr' || types === 'cdnra') {
            const matched = b2bArray.find(item => {
                return item.nt.some(invoice => invoice.nt_num === targetInum && item.ctin === targetctin)
            });
            return matched;
        }
    };

    const mergeData = (json2Adata, types, isDisplay = true) => {
        if (!json2Adata) {
            return;
        }
        let combinedData = [];
        let json2BArray = [];
        console.log(json2Adata);
        if (types === 'b2b' || types === 'b2ba') {
            const data2b = types === 'b2b' ? jsonData.b2b : jsonData.b2ba;
            const data2a = types === 'b2b' ? json2Adata.b2b : json2Adata.b2ba;
            if (!data2a || !data2b) {
                if (isDisplay) {
                    alert("There is no data");
                    setExistingData([]);
                }
                return;
            }
            data2b.forEach((b2bItem) => {
                b2bItem.inv.forEach((item) => {
                    const mergeData = { ...item, trdnm: b2bItem.trdnm, ctin: b2bItem.ctin, oinum: item.oinum, oidt: item.oidt, inum: item.inum, billval: item.val, idt: item.dt, inv_typ: item.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: item.srctyp ? item.srctyp : "--" }
                    json2BArray.push(mergeData);
                });
            });
            json2BArray = json2BArray.map(({ cgst, igst, sgst, cess, ...rest }) => rest);
            combinedData = json2BArray.map((item) => {
                const matchedItem = findInum(types, data2a, item.inum, item.ctin);
                if (matchedItem) {
                    const invoice = matchedItem.inv.find(invoice => invoice.inum === item.inum);
                    if (invoice) {
                        const mergedItems = invoice.itms.map((itm) => {
                            const itmDet = itm.itm_det;
                            return {
                                ...item, ...itmDet
                            };
                        }).flat();
                        console.log(mergedItems.length)
                        return mergedItems;
                    } else {
                        alert(item.ctin + '   ' + item.inum)
                    }

                } else {
                    alert(item.ctin + '   ' + item.inum)
                }
                return [item];
            }).flat();
            if (!isDisplay) {
                return combinedData;
            }
        }
        if (types === 'cdnr' || types === 'cdnra') {
            const data2b = types === 'cdnr' ? jsonData.cdnr : jsonData.cdnra;
            const data2a = types === 'cdnr' ? json2Adata.cdnr : json2Adata.cdnra;
            console.log(data2a)
            if (!data2a || !data2b) {
                if (isDisplay) {
                    alert("There is no data");
                    setExistingData([]);
                }
                return;
            }
            data2b.forEach((cdnrItem) => {
                cdnrItem.nt.forEach((item) => {
                    const mergeData = { ...item, trdnm: cdnrItem.trdnm, ctin: cdnrItem.ctin, oinum: item.ont_num, oidt: item.ont_dt, inum: item.ntnum, billval: item.val, idt: item.dt, inv_typ: item.typ, iamt: item.igst ? item.igst : 0, camt: item.cgst ? item.cgst : 0, samt: item.sgst ? item.sgst : 0, csamt: item.cess ? item.cess : 0, source: item.srctyp ? item.srctyp : "--" }
                    json2BArray.push(mergeData);
                });
            });

            json2BArray = json2BArray.map(({ cgst, igst, sgst, cess, ...rest }) => rest);

            combinedData = json2BArray.map((item) => {
                const matchedItem = findInum(types, data2a, item.inum, item.ctin);
                if (matchedItem) {
                    const invoice = matchedItem.nt.find(invoice => invoice.nt_num === item.inum);
                    if (invoice) {
                        const mergedItems = invoice.itms.map((itm) => {
                            const itmDet = itm.itm_det;
                            return {
                                ...item, ...itmDet
                            };
                        }).flat();
                        return mergedItems;
                    }
                }
                return [item];
            }).flat();
            if (!isDisplay) {
                return combinedData;
            }
        }
        if (isDisplay) {
            let updatedData = sortedArray(combinedData)
            updatedData = addNum(updatedData);
            setrtExists(true);
            setExistingData(updatedData);
        }
    };

    const handleExportToExcel = async () => {
        if (existingData.length === 0) {
            alert("There is no Existing Data");
            return;
        }
        const fileMonth = u.getMonth("01/" + filePrd);
        const intPrd = Number(filePrd.substring(5));
        let FinancialYear = '';
        if (filePrd.substring(0, 2) <= "03") {
            FinancialYear = "20" + (intPrd - 1) + "-" + filePrd.substring(5)
        }
        else {
            FinancialYear = filePrd.substring(3) + "-" + (intPrd + 1);
        }
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Read me');

        const labelCell = worksheet.getCell('A1');
        labelCell.value = `Gen. By`;
        labelCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valueCell = worksheet.getCell('C1');
        valueCell.value = `RAINBOW`;
        valueCell.alignment = { vertical: 'left', horizontal: 'center' };

        const labelyearCell = worksheet.getCell('A4');
        labelyearCell.value = `Financial Year`;
        labelyearCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valueyearCell = worksheet.getCell('C4');
        valueyearCell.value = `${FinancialYear}`;
        valueyearCell.alignment = { vertical: 'left', horizontal: 'center' };

        const labeltpCell = worksheet.getCell('A5');
        labeltpCell.value = `Tax Period`;
        labeltpCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valuetpCell = worksheet.getCell('C5');
        valuetpCell.value = `${fileMonth}`;
        valuetpCell.alignment = { vertical: 'left', horizontal: 'center' };

        const labelgstinCell = worksheet.getCell('A6');
        labelgstinCell.value = `GSTIN`;
        labelgstinCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valuegstinCell = worksheet.getCell('C6');
        valuegstinCell.value = `${gstin}`;
        valuegstinCell.alignment = { vertical: 'left', horizontal: 'center' };

        const labelgenerationCell = worksheet.getCell('A9');
        labelgenerationCell.value = `Date of generation`;
        labelgenerationCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valuegenerationCell = worksheet.getCell('C9');
        valuegenerationCell.value = `RAINBOW`;
        valuegenerationCell.alignment = { vertical: 'left', horizontal: 'center' };

        const b2bSheet = workbook.addWorksheet("B2B");
        const labelb2bCell = b2bSheet.getCell('A1');
        labelb2bCell.value = `TABLE`;
        labelb2bCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valueb2bCell = b2bSheet.getCell('C1');
        valueb2bCell.value = `B2B`;
        valueb2bCell.alignment = { vertical: 'left', horizontal: 'center' };

        const headers = ["GSTIN of supplier", "Trade/Legal name", "Invoice number", "Invoice type", "Invoice Date", "Invoice Value(₹)",
            "Place of supply", "Supply Attract Reverse Charge", "Rate Of Tax", "Taxable Value (₹)", "Integrated Tax(₹)", "Central Tax(₹)", "State/UT Tax(₹)", "Cess(₹)",'','','ITC Availability'];
        b2bSheet.getRow(6).values = headers;

        headers.forEach((header, index) => {
            const cell = b2bSheet.getRow(6).getCell(index + 1);
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
        });
        b2bSheet.columns = [{ key: "ctin", width: 20 }, { key: "trdnm", width: 25 }, { key: "inum", width: 25 }, { key: "inv_typ", width: 15 },
        { key: "idt", width: 20 }, { key: "billval", width: 15 }, { key: "pos", width: 15 }, { key: "rev", width: 15 }, { key: "rt", width: 15 },
        { key: "txval", width: 15 }, { key: "iamt", width: 10 }, { key: "camt", width: 10 },
        { key: "samt", width: 10 }, { key: "csamt", width: 10 },{ key: "", width: 10 },{ key: "", width: 10 },{ key: "itcavl", width: 10 },];

        const b2bData = gstrtwiseBreakUp ? mergeData(gstr2AObj, 'b2b', false) : pushData(jsonData, 'b2b', false);
        const processedB2bData = Array.isArray(b2bData) ? b2bData : [];
        if (processedB2bData.length > 0) {
            processedB2bData.forEach((row, index) => {
                const statename = statesData.find(states => states["gstcode"] === row.pos);
                b2bSheet.addRow([row.ctin, row.trdnm, row.inum, row.inv_typ === 'R' ? "Regular" : row.inv_typ, row.idt, row.billval, statename.name, row.rev === "Y" ? "Yes" : "No", row.rt, row.txval,
                row.iamt, row.camt, row.samt, row.csamt,'','', row.itcavl === 'Y' ? 'Yes' : 'No']);
            });
        }
        else {
            b2bSheet.addRow([]);
        }
        const b2baSheet = workbook.addWorksheet("B2BA");
        const labelb2baCell = b2baSheet.getCell('A1');
        labelb2baCell.value = `TABLE`;
        labelb2baCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valueb2baCell = b2baSheet.getCell('C1');
        valueb2baCell.value = `B2BA`;
        valueb2baCell.alignment = { vertical: 'left', horizontal: 'center' };

        const b2baheaders = ["O-Invoice number", "O-Invoice Date", "GSTIN of supplier", "Trade/Legal name", "Invoice number", "Invoice type", "Invoice Date", "Invoice Value(₹)",
            "Place of supply", "Supply Attract Reverse Charge", "Rate Of Tax", "Taxable Value (₹)", "Integrated Tax(₹)", "Central Tax(₹)", "State/UT Tax(₹)", "Cess(₹)",'','','ITC Availability'];
        b2baSheet.getRow(7).values = b2baheaders;

        b2baheaders.forEach((header, index) => {
            const cell = b2baSheet.getRow(7).getCell(index + 1);
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
        });
        b2baSheet.columns = [{ key: "oinum", width: 20 }, { key: "oidt", width: 20 }, { key: "ctin", width: 20 }, { key: "trdnm", width: 25 }, { key: "inum", width: 25 }, { key: "inv_typ", width: 15 },
        { key: "idt", width: 20 }, { key: "billval", width: 15 }, { key: "pos", width: 15 }, { key: "rev", width: 15 },
        { key: "rt", width: 15 }, { key: "txval", width: 15 }, { key: "iamt", width: 10 }, { key: "camt", width: 10 },
        { key: "samt", width: 10 }, { key: "csamt", width: 10 },{ key: "", width: 10 },{ key: "", width: 10 },{ key: "itcavl", width: 10 },];

        const b2baData = gstrtwiseBreakUp ? mergeData(gstr2AObj, 'b2ba', false) : pushData(jsonData, 'b2ba', false)
        const processedB2baData = Array.isArray(b2baData) ? b2baData : [];
        if (processedB2baData.length > 0) {
            processedB2baData.forEach((row, index) => {
                const statename = statesData.find(states => states["gstcode"] === row.pos);
                b2baSheet.addRow([row.oinum, row.oidt, row.ctin, row.trdnm, row.inum, row.inv_typ === 'R' ? "Regular" : row.inv_typ, row.idt, row.billval, statename.name, row.rev === "Y" ? "Yes" : "No", row.rt, row.txval,
                row.iamt, row.camt, row.samt, row.csamt,'','', row.itcavl === 'Y' ? 'Yes' : 'No']);
            });
        }
        else {
            b2baSheet.addRow([]);
        }

        const cdnrSheet = workbook.addWorksheet("B2B-CDNR");
        const labelcdnrCell = cdnrSheet.getCell('A1');
        labelcdnrCell.value = `TABLE`;
        labelcdnrCell.alignment = { vertical: 'right', horizontal: 'center' };

        const valuecdnrCell = cdnrSheet.getCell('C1');
        valuecdnrCell.value = `CDNR`;
        valuecdnrCell.alignment = { vertical: 'left', horizontal: 'center' };
        const cdnrheaders = ["GSTIN of supplier", "Trade/Legal name", "Note number", "Note type", "Note Supply type", "Note Date", "Note Value(₹)",
            "Place of supply", "Supply Attract Reverse Charge", "Rate Of Tax", "Taxable Value (₹)", "Integrated Tax(₹)", "Central Tax(₹)", "State/UT Tax(₹)", "Cess(₹)",'','','ITC Availability'];
        cdnrSheet.getRow(6).values = cdnrheaders;

        cdnrheaders.forEach((header, index) => {
            const cell = cdnrSheet.getRow(6).getCell(index + 1);
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
        });
        cdnrSheet.columns = [{ key: "ctin", width: 20 }, { key: "trdnm", width: 25 }, { key: "inum", width: 25 }, { key: "inv_typ", width: 15 },{ key: "suptyp", width: 15 },
        { key: "idt", width: 20 }, { key: "billval", width: 15 }, { key: "pos", width: 15 }, { key: "rev", width: 15 },
        { key: "rt", width: 15 }, { key: "txval", width: 15 }, { key: "iamt", width: 10 }, { key: "camt", width: 10 },
        { key: "samt", width: 10 }, { key: "csamt", width: 10 },{ key: "", width: 10 },{ key: "", width: 10 },{ key: "itcavl", width: 10 },];

        const cdnrData = gstrtwiseBreakUp ? mergeData(gstr2AObj, 'cdnr', false) : pushData(jsonData, 'cdnr', false)
        const processedCdnrData = Array.isArray(cdnrData) ? cdnrData : [];
        if (processedCdnrData.length > 0) {
            processedCdnrData.forEach((row, index) => {
                const statename = statesData.find(states => states["gstcode"] === row.pos);
                cdnrSheet.addRow([row.ctin, row.trdnm, row.inum, row.inv_typ === 'C' ? "Credit Note" : "Debit Note", row.suptyp === 'R' ? "Regular" : row.suptyp, row.idt, row.billval, statename.name, row.rev === "Y" ? "Yes" : "No", row.rt, row.txval,
                row.iamt, row.camt, row.samt, row.csamt,'','', row.itcavl === 'Y' ? 'Yes' : 'No']);
            });
        }
        else {
            cdnrSheet.addRow([]);
        }

        const cdnraSheet = workbook.addWorksheet("B2B-CDNRA");
        const labelcdnrACell = cdnraSheet.getCell('A1');
        labelcdnrACell.value = `TABLE`;
        labelcdnrACell.alignment = { vertical: 'right', horizontal: 'center' };

        const valuecdnrACell = cdnraSheet.getCell('C1');
        valuecdnrACell.value = `CDNRA`;
        valuecdnrACell.alignment = { vertical: 'left', horizontal: 'center' };
        const cdnraheaders = ["O-Note type", "O-Note Number", "O-Note Date", "GSTIN of supplier", "Trade/Legal name", "Note number", "Note type", "Note Supply type", "Note Date", "Note Value(₹)",
            "Place of supply", "Supply Attract Reverse Charge", "Rate Of Tax", "Taxable Value (₹)", "Integrated Tax(₹)", "Central Tax(₹)", "State/UT Tax(₹)", "Cess(₹)",'','','ITC Availability'];
        cdnraSheet.getRow(7).values = cdnraheaders;

        cdnraheaders.forEach((header, index) => {
            const cell = cdnraSheet.getRow(7).getCell(index + 1);
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' };
        });
        cdnraSheet.columns = [{ key: "oinum", width: 25 }, { key: "typ", width: 20 }, { key: "oidt", width: 20 }, { key: "ctin", width: 20 }, { key: "trdnm", width: 25 }, { key: "inum", width: 25 }, { key: "inv_typ", width: 15 }, { key: "suptyp", width: 15 },
        { key: "idt", width: 20 }, { key: "billval", width: 15 }, { key: "pos", width: 15 }, { key: "rev", width: 15 },
        { key: "rt", width: 15 }, { key: "txval", width: 15 }, { key: "iamt", width: 10 }, { key: "camt", width: 10 },
        { key: "samt", width: 10 }, { key: "csamt", width: 10 },{ key: "", width: 10 },{ key: "", width: 10 },{ key: "itcavl", width: 10 },];

        const cdnraData = gstrtwiseBreakUp ? mergeData(gstr2AObj, 'cdnra', false) : pushData(jsonData, 'cdnra', false)
        const processedCdnraData = Array.isArray(cdnraData) ? cdnraData : [];
        if (processedCdnraData.length > 0) {
            processedCdnraData.forEach((row, index) => {
                const statename = statesData.find(states => states["gstcode"] === row.pos);
                cdnraSheet.addRow([row.oinum, row.typ, row.oidt, row.ctin, row.trdnm, row.inum, row.inv_typ === 'C' ? "Credit Note" : "Debit Note", row.suptyp === 'R' ? "Regular" : row.suptyp, row.idt, row.billval, statename.name, row.rev === "Y" ? "Yes" : "No", row.rt, row.txval,
                row.iamt, row.camt, row.samt, row.csamt,'','', row.itcavl === 'Y' ? 'Yes' : 'No']);
            });
        }
        else {
            cdnraSheet.addRow([]);
        }
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const xstr = filePrd.replace("/","")
        saveAs(blob, `${xstr}_${gstin.toUpperCase()}_GSTR2B_RAINBOW.xlsx`);
    }

    return (
        <div className='gstr2b-divmain'>
            <div className="gstr2b-formcontainer">
                <div className='gstr2b-header'>
                    <h6>{toolname}</h6>
                    <div className='gstr2b-mymarquee' >
                        <Marquee velocity={25}>
                            <h3>{formValue[0].id_value}</h3>
                        </Marquee>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                        <FaYoutube style={{ color: "#ff0000", fontSize: "28px", marginRight: "2px" }} onClick={() => window.open(formValue[1].id_value, "_blank", "noopener, noreferrer")} />
                        <AiOutlineClose onClick={() => navigate("/emptypage")} style={{ backgroundColor: "#ff0000", color: "white", fontSize: "18px" }} />
                    </div>
                </div>
                <div className='gstr2b-toppanel'>
                    <div className="input-group">
                        <select id='typeofdata'
                            name='typeofdata'
                            onChange={handleDataChange}
                            value={typeofdata}
                            style={{ width: "40%" }}>
                            <option value='' disabled selected>Choose</option>
                            {dataobj.map(data => (
                                <option key={data}
                                    value={data}>{data}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className='file-upload-div'>
                        <label htmlFor="file-upload-2b" style={{ paddingTop: "3px" }} className="file-upload-button" >Imp.GSTR2B JSON</label>
                        <input type="file" accept=".json, .zip" multiple={false} id="file-upload-2b"
                            onChange={handleImport2BJson}
                            style={{ width: "0", height: "22px", display: "none" }}
                            ref={json2BInputRef} />
                    </div>


                    <div className="input-group">
                        <label>Period:</label>
                        <input type='text' id='fp' value={filePrd}
                            style={{ width: "35%" }}
                            readOnly>

                        </input>
                    </div>
                    <div className="input-group">
                        <label>GSTIN:</label>
                        <input type="text" value={gstin} readOnly />
                    </div>
                    {(gstrtwiseBreakUp && !uploaded) && (toolname === 'GSTR2B') &&
                        <div className='file-upload-div'>
                            <button onClick={toggleModal}>Imp.GSTR2A Json</button>
                        </div>
                    }
                    {(!gstrtwiseBreakUp || uploaded) && (toolname === 'GSTR2B') &&
                        <div className='file-upload-div'>
                            <button onClick={handleExportToExcel}>Generate Excel</button>
                        </div>
                    }

                    {isModalOpen && (
                        <div className="GSTR2A-modal">
                            <div className="GSTR2A-modal-content">
                                <h2>Upload Files</h2>
                                {Month2AArray.map((month, index) => (
                                    <div key={month + index} className="GSTR2A-upload-container">
                                        <label>{month}</label>
                                        <input
                                            type="file"
                                            onChange={(e) => handleFileChange(e, month)}
                                            accept=".json, .zip" multiple={false}
                                            id={`upload-${month}`}
                                            style={{ display: "none" }}
                                            disabled={!!uploadedFiles[month]}
                                        />
                                        <button
                                            onClick={() => document.getElementById(`upload-${month}`).click()}
                                            className={`GSTR2A-upload-button ${uploadedFiles[month] ? "GSTR2A-uploaded" : ""}`}
                                        >
                                            {uploadedFiles[month] ? "Uploaded" : "Upload"}
                                        </button>

                                    </div>
                                ))}
                                <div className="GSTR2A-btngrp">
                                    <button onClick={toggleModal} className="cancel-btn" >Cancel</button>
                                    <button id='ok' onClick={handleUploadOk} className="ok-btn">OK</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <div className='gstr2b-container'>
                    <div className="gstr2b-agegrid ">
                        <div className="aag-theme-alpine" style={{ width: "100%", height: "100%" }}>
                            <AgGridReact
                                ref={gridRef}
                                rowHeight={23}
                                rowData={existingData}
                                columnDefs={b2bcdnrcolumnDefs}
                                domLayout="normal"
                                defaultColDef={{ resizable: true, flex: 1 }}
                                pinnedBottomRowData={pinnedBottomRowData}
                                getRowStyle={getRowStyle}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AgeGridForm;
