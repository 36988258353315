
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './PriceList.css'
import { useSelector } from 'react-redux';
import * as u from "../utils/Common";

const PriceList = () => {
  const myNavigate = useNavigate()

  const [pricelistnoValue, setpricelistnoValue] = useState(1);
  const [pricelistnameValue, setpricelistnameValue] = useState('');

  const pricelistnoRef = useRef(null);
  const pricelistnameRef = useRef(null);

  let url = "";

  const defaultEnterRef = useRef(null)
  const defaultEscapeRef = useRef(null)

  const curmode = useSelector(function (data) {
    return data.pricelistmode;
  })
 

  console.log(curmode[0]);
  

  useEffect(() => {
    if (curmode[0] === "Edit") { 
      console.log(curmode[1]);
       pricelistnoRef.current.value = curmode[1];    
     
        setpricelistnoValue(curmode[1]);
        
    }
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        defaultEnterRef.current.click();
      }
      if (event.key === 'Escape') {
        defaultEscapeRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const fetchdata = async (e) => {
      const data = { pricelistno: curmode[1] };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/pricelist_readpricelistno`, data)
      if (response.data.length > 0) {
        pricelistnameRef.current.value = response.data[0].pricelistname;
        setpricelistnameValue(response.data[0].pricelistname);
        setpricelistnoValue(curmode[1]);
      }
    }

    if (curmode[0] === "New") {
      pricelistnoRef.current.value = 1;
      setpricelistnoValue(1);
      axios.get(`${process.env.REACT_APP_RAINBOW_URL}/pricelist_lastpricelistno`)
        .then(response => {
          pricelistnoRef.current.value = response.data.pricelistno + 1;
          setpricelistnoValue(response.data.pricelistno + 1);
        })
        .catch(error => {
          console.error('Error fetching last record:', error);
        });
    }
    if (curmode[0] === "Edit") {
      fetchdata();
    }
  }, [curmode]);

  useEffect(() => {
    pricelistnameRef.current.focus();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const pricelistno = u.myInt(pricelistnoValue);
    const pricelistname = u.myTrim(pricelistnameValue);

    if (!pricelistno > 0) {
      alert("Price List No. Should be given..");
      pricelistnoRef.current.focust();
      return;
    }

    if (!pricelistname === "") {
      alert("Price List Name Should be Given")
      pricelistnameRef.current.focust();
      return;
    }

    const data = {
      pricelistno: pricelistno,
      pricelistname: pricelistname
    };

    if (curmode[0]==="New") {
      try {
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/pricelist_readpricelistno`, data);
        if (response.data.length > 0) {
          alert('Price List already Given ')
          return;
        }
      } catch (error) {
        console.error('Error submitting form:', error);
      }
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/pricelist_readpricelistname`, data);
      if (response.data.length > 0) {
        alert('Price List Name already Given ')
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }

    try {
      url = `${process.env.REACT_APP_RAINBOW_URL}/pricelist_create`
      if (curmode[0] === "Edit") {
        url = `${process.env.REACT_APP_RAINBOW_URL}/pricelist_update`
      }
      const response = await axios.post(url, data);

      myNavigate("/listpricelist")

    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };


  const handlepricelistnamechange = (event) => {
    const { value } = event.target;
    setpricelistnameValue(u.toProperCase(value));
  };

  const handlepricelistnochange = (event) => {
    const { value } = event.target;
    setpricelistnoValue(u.myInt(value));
  };


  return (
    <div className="pricelist-divmain">
      <h2>Price List</h2>
      <div className="pricelist-form-container">
        <form onSubmit={handleSubmit} className="pricelist-form">
          <div className="pricelist-form-group">
            <label htmlFor="pricelistno" title=''>No. :</label>
            <input
              type="number"
              id="pricelistno"
              style={{ width: '50px' }}
              ref={pricelistnoRef}
              value={pricelistnoValue}
              onChange={handlepricelistnochange}
              required
              readOnly={curmode[0] === "New" ? false : true}
              tabIndex={curmode[0] === "New" ? 1 : -1} />
          </div>
          <div className="pricelist-form-group">
            <label htmlFor="pricelistname">Name:</label>
            <input
              type="text"
              value={pricelistnameValue}
              onChange={handlepricelistnamechange}
              id="pricelistname"
              ref={pricelistnameRef}
              tabIndex="2"
              required />
          </div>
          <div className='updateBack'>
            <button tabIndex="4" onClick={() => myNavigate("/listpricelist")} ref={defaultEscapeRef}>back</button>
            <button type="submit" tabIndex="3" ref={defaultEnterRef} >Update</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PriceList;