import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useRef, useEffect, useCallback } from 'react';
import axios from 'axios';
import * as u from "../utils/Common";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useNavigate } from 'react-router-dom';
import './DeliveryChalanLookup.css';
import { FaSearch } from 'react-icons/fa';
import { AiOutlinePrinter, AiOutlinePlus, AiOutlineClose, AiOutlineFile } from 'react-icons/ai';
import Swal from 'sweetalert2';
import { NumericFormat } from 'react-number-format';
import InputMask from 'react-input-mask';
import { myaction } from '../../redux/Mystore';

const DeliveryChalanLookup = ({ chalanId }) => {
    let chalanName = '';
    let navigateurl = '';
    if (chalanId === 1) {
        chalanName = 'Branch Transfer';
        navigateurl = "/installdeliverychalan-branchtransfer";
    }
    if (chalanId === 2) {
        chalanName = 'Job Based';
        navigateurl = "/installdeliverychalan-jobbased";
    }

    const mydispatch = useDispatch()
    const userid = useSelector(function (data) {
        return data.userid;
    })
    const coyid = useSelector(function (data) {
        return data.coyid;
    })
    const [curdata, setcurdata] = useState([]);
    const searchNameRef = useRef(null)
    const [markall, setMarkAll] = useState(false);
    const gridRef = useRef('')

    // useEffect(() => {
    //     searchNameRef.current.focus();
    // }, []);

    const fetchdata = async () => {
        try {
            const data = {
                coyid: coyid,
                trnid: chalanId
            }
            const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_readall`, data);
            console.log(response)
            setcurdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };

    useEffect(() => {
        fetchdata();
    }, [coyid, userid]);


    const handleCheckboxChange = (value) => {
        setMarkAll(value);
        const updatedData = curdata.map((row) => ({ ...row, selected: value }));
        setcurdata(updatedData);
    }

    const CheckboxRenderer = (props) => {
        const [isChecked, setIsChecked] = useState(props.value);
        useEffect(() => {
            setIsChecked(props.value);
        }, [props.value]);

        const handleCheckboxChange = () => {
            const newCheckedState = !isChecked;
            setIsChecked(newCheckedState);

            props.node.setDataValue(props.colDef.field, newCheckedState);

            props.api.refreshCells({ rowNodes: [props.node], force: true });
        };

        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                />
            </div>
        );
    };
    const [columnDefs] = useState([
        {
            headerClass: 'custom-header',
            headerName: "Select",
            field: "selected",
            flex: 0.1,
            cellRenderer: CheckboxRenderer,
        },
        { headerName: 'Ref.No', field: 'docuno', headerClass: 'custom-header', sortable: false, filter: true, flex: 0.3, resizable: true, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' }},
        { headerName: 'Date', field: 'docudate', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.5, cellStyle: { overflow: 'hidden', textAlign: 'center', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Delivery At', field: 'actname', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 2, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'EWay Bill No.', field: 'ewaybillno', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        { headerName: 'Goods Value', field: 'goodsvalue', headerClass: 'custom-header', sortable: false, filter: false, resizable: true, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'right', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        // { headerName: 'D', field: 'discount', headerClass: 'custom-header', sortable: false, filter: false, resizable: true, flex: 0.28, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        // { headerName: 'Pvt. Mark', field: 'pvtmark', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.8, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },
        // { headerName: 'Price List', field: 'pricelistname', headerClass: 'custom-header', sortable: false, filter: true, resizable: true, flex: 0.7, cellStyle: { overflow: 'hidden', textAlign: 'left', textOverflow: 'ellipsis', whiteSpace: 'nowrap', borderRight: '1px solid #ccc', paddingLeft: '5px', paddingRight: '5px' } },

        {
            headerName: 'Actions',
            field: 'actions',
            cellRenderer: (params) => (
                <div className="col-action-div action-cell">
                    <button onClick={() => handleEdit(params.data)}>Edit</button>
                    <button onClick={() => handleDelete(params.data)}>Delete</button>
                    <button onClick={() => handlePrint(params.data)}>Print</button>
                    {params.data.isCancelled === 'Y' &&
                        <button style={{ backgroundColor: "darkolivegreen" }}
                            onMouseEnter={(e) => (e.target.style.backgroundColor = "blue")}
                            onMouseLeave={(e) => (e.target.style.backgroundColor = "darkolivegreen")}
                            onClick={() => handleActive(params.data)}>Active</button>
                    }
                    {params.data.isCancelled !== 'Y' &&
                        <button style={{ backgroundColor: "darkorange" }}
                            onMouseEnter={(e) => (e.target.style.backgroundColor = "blue")}
                            onMouseLeave={(e) => (e.target.style.backgroundColor = "darkorange")}
                            onClick={() => handleCancel(params.data)}>Cancel</button>
                    }
                    <button onClick={() => handleEWB(params.data)}>EWB</button>
                </div>
            ),
            headerClass: 'custom-header',
            flex: 1.3,
            cellStyle: { whiteSpace: "nowrap" }
        }

    ]);
    const [ewaydetails, setEwayDetails] = useState({});
    const handleEWB = (data) => {
        if (data.isCancelled === 'Y') {
            alert("Cannot fill EWB for Cancelled Records");
            return;
        }
        setEwayModal(true);
        setTimeout(() => {
            window.document.getElementById("ewaybillno").select();
            window.document.getElementById("ewaybillno").focus();
        }, [500])
        setEwayDetails({ ...data, ewaybilldate: data.ewaybilldate === "Invalid date" ? '' : data.ewaybilldate })
    }

    const handleActive = (data) => {
        Swal.fire({
            title: `Are you Sure to Active Ref.No ${data.docuno} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmActive(data);
            }
        });
    }

    const confirmActive = async (data) => {
        const userdata = {
            docuno: data.docuno,
            coyid: coyid,
            trnid: chalanId,
            isCancelled: 'N',
            dom: u.curdatetime()
        }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_cancelactive`
        try {
            await axios.post(url, userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error activing the data!', error);
        }
    };

    const handleCancel = (data) => {
        Swal.fire({
            title: `Are you Sure to Cancel Ref.No ${data.docuno} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmCancel(data);
            }
        });
    }

    const confirmCancel = async (data) => {
        const userdata = {
            docuno: data.docuno,
            coyid: coyid,
            trnid: chalanId,
            isCancelled: 'Y',
            dom: u.curdatetime()
        }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_cancelactive`
        try {
            await axios.post(url, userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error cancelling the data!', error);
        }
    };

    const handleDelete = (data) => {
        if (data.isCancelled === 'Y') {
            alert("Cannot Delete the Cancelled Records");
            return;
        }
        if (data.ewaybillno) {
            alert("Cannot Delete the EWay Bill Passed Records");
            return;
        }
        Swal.fire({
            title: `Are you Sure to Delete Ref.No ${data.docuno} ?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'No'
        }).then((result) => {
            if (result.isConfirmed) {
                confirmDelete(data);
            }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = {
            docuno: data.docuno,
            coyid: coyid,
            trnid: chalanId
        }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_delete`
        try {
            await axios.post(url, userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };
    const handleEdit = (data) => {
        console.log(data)
        if (data.isCancelled === 'Y') {
            alert("Cannot Edit the Cancelled Records");
            return;
        }
        if (data.ewaybillno) {
            alert("Cannot Edit the EWay Bill Passed Records");
            return;
        }
        mydispatch(myaction.setdeliverychalanmode(["Edit", data.docuno]));
        navigate(navigateurl)
    }

    const handlePrint = async (data) => {
        if (data.isCancelled === 'Y') {
            alert("Cannot Print the Cancelled Records");
            return;
        }
        const xdata = { docuno: [data.docuno] }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_preview`
        const response = await axios.post(url, xdata, { responseType: 'blob' });
        const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(fileURL, '_blank');
    }

    const handleAddNew = () => {
        mydispatch(myaction.setdeliverychalanmode(["New", 0]));
        navigate(navigateurl);
    }

    const navigate = useNavigate()

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    // const onGridReady = (params) => {
    //     gridRef.current = params.api;
    // }

    const handleFilterTextChange = (filterValue, filterName) => {
        const filterModel = {
            [filterName]: {
                filterType: "text",
                type: "contains",
                filter: filterValue,
            },
        }
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setFilterModel(filterModel);
            gridRef.current.api.onFilterChanged();
        }

        // setTimeout(() => {
        //     const displayedRowCount = gridRef.current.api.getDisplayedRowCount();
        //     if (displayedRowCount > 0) {
        //         const firstVisibleNode = gridRef.current.api.getDisplayedRowAtIndex(0);
        //         if (firstVisibleNode) {
        //             firstVisibleNode.setSelected(true);
        //             gridRef.current.api.ensureIndexVisible(firstVisibleNode.rowIndex);
        //         }
        //     }
        // }, 1)
    };

    const handlePrintSelected = async () => {
        let selectedData = curdata.filter((item) => { return (item.selected === true) })
        selectedData = selectedData.map((item) => {
            return (item.docuno);
        })
        if (!selectedData.length > 0) {
            alert("No Items are Selected to Print");
            return;
        }
        const xdata = { docuno: selectedData }
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_preview`
        const response = await axios.post(url, xdata, { responseType: 'blob' });
        const fileURL = URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        window.open(fileURL, '_blank');
        // setSelectedList([]);
        gridRef.current.api.deselectAll();
        gridRef.current.api.focusCell({ rowIndex: 0, colId: 'refno' });
    }

    const handleJsonDownload = async () => {
        let selectedData = curdata.filter((item) => { return (item.selected === true) })
        selectedData = selectedData.map((item) => {
            return (item.docuno);
        })
        if (!selectedData.length > 0) {
            alert("No Items are Selected to Print");
            return;
        }
        try {
            const xdata = { docuno: selectedData }
            const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_readjson`
            const response = await axios.post(url, xdata);
            const jsonString = JSON.stringify(response.data, null, 1);
            const blob = new Blob([jsonString], { type: 'application/json' });
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = `deliverychalan.json`;
            link.click();

            // Cleanup: revoke the object URL after the download
            URL.revokeObjectURL(link.href);
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    }

    const getRowStyle = (params) => {
        if (params.data.isCancelled === 'Y') {
            return { backgroundColor: "pink" };
        }
        if (params.node.rowIndex % 2 === 0) {
            return { backgroundColor: "#F5F5F5" };
        }
        else {
            return { backgroundColor: "#ffffff" };
        }
    };

    const [currentRowIndex, setCurrentRowIndex] = useState(0); // Start from the first row (index 0)

    useEffect(() => {
        setTimeout(() => {
            if (gridRef.current && gridRef.current.api) {
                const gridApi = gridRef.current.api;
                const totalRows = gridApi.getDisplayedRowCount();
                if (totalRows > 0) {
                    const firstRowNode = gridApi.getDisplayedRowAtIndex(0);
                    if (firstRowNode) {
                        firstRowNode.setSelected(true); // Select the first row by default
                    }
                }
            }
        }, [500])
    }, []);

    const handleOnKeyDown = (event) => {
        if (!gridRef.current) return;

        const gridApi = gridRef.current.api;
        const totalRows = gridApi.getDisplayedRowCount();
        let newRowIndex = currentRowIndex;

        if (event.key === 'ArrowDown') {
            newRowIndex = currentRowIndex < totalRows - 1 ? currentRowIndex + 1 : currentRowIndex;
        } else if (event.key === 'ArrowUp') {
            newRowIndex = currentRowIndex > 0 ? currentRowIndex - 1 : 0;
        } else if (event.key === ' ') { // Spacebar key
            const rowNode = gridApi.getDisplayedRowAtIndex(currentRowIndex);
            if (rowNode) {
                const docuno = rowNode.data?.docuno;
                console.log(`Spacebar pressed on Doc No: ${docuno}`);
                selectRowsBydocuno(docuno); // Your logic for handling spacebar
            }
            return; // Stop further execution for spacebar
        } else {
            return; // Ignore other keys
        }

        // Update the current row index and selection
        if (newRowIndex !== currentRowIndex) {
            setCurrentRowIndex(newRowIndex);
            const rowNode = gridApi.getDisplayedRowAtIndex(newRowIndex);
            rowNode.setSelected(true); // Select the row
            gridApi.ensureIndexVisible(newRowIndex); // Scroll to the selected row
        }
    };


    const onGridReady = (params) => {
        gridRef.current = params.api;
        setTimeout(() => params.api?.gridBodyComp?.focus());
    };

    const selectRowsBydocuno = (docuno) => {
        if (gridRef.current && gridRef.current.api) {
            let allSelected = true;
            gridRef.current.api.forEachNode((node) => {
                if (node.data.docuno === docuno) {
                    if (!node.data.selected) {
                        node.setDataValue('selected', true);
                    }
                    else {
                        node.setDataValue('selected', false);
                    }
                }
                if (!node.data.selected) {
                    allSelected = false;
                }
            });
            if (allSelected) {
                setMarkAll(true);
            }
            else {
                setMarkAll(false)
            }

            gridRef.current.api.refreshCells({
                force: true,
            });
        }
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            handleOnKeyDown(event);
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [currentRowIndex]);

    const [ewayModal, setEwayModal] = useState(false);

    const onEnterkeydown = (e, nextid) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (nextid === 'ok') {
                window.document.getElementById(nextid).focus();
                return;
            }
            window.document.getElementById(nextid).select();
            window.document.getElementById(nextid).focus();
        }
    }

    const checkBillNoExists = async (billno) => {
        const data = {
            ewaybillno: billno,
            docuno: ewaydetails.docuno,
            coyid: ewaydetails.coyid,
            trnid: ewaydetails.trnid,
        };
        const url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_checkEWBNoExists`
        const response = await axios.post(url, data);
        if (response.data.length > 0) {
            return true;
        }
        return false;
    }


    const handleDetailsChange = async (value, name) => {
        if (name === 'ewaybillno') {
            if (String(value).length === 12) {
                const ewbillnoExists = await checkBillNoExists(value);
                if (ewbillnoExists) {
                    alert("This EWay Bill No. Already Exists");
                    window.document.getElementById("ewaybillno").select();
                    window.document.getElementById("ewaybillno").focus();
                    return;
                }
            }
        }
        setEwayDetails({ ...ewaydetails, [name]: value })
    }

    const handleEwaySave = async () => {
        const ewaybillnovalue = ewaydetails.ewaybillno ? String(ewaydetails.ewaybillno) : '';
        const ewaybilldatevalue = ewaydetails.ewaybilldate ? String(ewaydetails.ewaybilldate) : '';

        if (ewaybillnovalue.length > 0 && ewaybillnovalue.length < 12) {
            alert("Invalid EWay Bill No.");
            window.document.getElementById("ewaybillno").select();
            window.document.getElementById("ewaybillno").focus();
            return;
        }
        if (ewaybillnovalue.length > 0 && ewaybilldatevalue.length === 0) {
            alert('EWay Bill Date Should be Given');
        }
        if (ewaydetails.ewaybilldate && ewaydetails.ewaybilldate !== "Invalid date") {
            if (!u.validateDate(ewaydetails.ewaybilldate, true)) {
                alert("Invalid EWay Bill Date");
                window.document.getElementById("ewaybilldate").select();
                window.document.getElementById("ewaybilldate").focus();
                return;
            }
        }
        try {
            const data = {
                docuno: ewaydetails.docuno,
                coyid: ewaydetails.coyid,
                trnid: ewaydetails.trnid,
                ewaybillno: ewaydetails.ewaybillno,
                ewaybilldate: ewaydetails.ewaybilldate,
                dom: u.curdatetime()
            }
            let url = `${process.env.REACT_APP_RAINBOW_URL}/deliverychalan_ewayupdate`
            const res = await axios.post(url, data);
            if (res.data.Success) {
                alert("Saved Successfully");
                setEwayModal(false);
                fetchdata()
            }
        }
        catch (error) {
            console.log("Error:", error);
        }
    }

    return (
        <div id="DeliveryChalan" className='DeliveryChalan-divmain' >
            <div className='DeliveryChalan-top'>
                <h3>Delivery Entry ({chalanName})</h3>
            </div>
            <div className="DeliveryChalan-container">

                <div className='DeliveryChalan-btngrp'>
                    <div className='DeliveryChalan-checkboxgrp'>
                        <input type="checkbox" name='markall' checked={markall} onChange={(e) => handleCheckboxChange(e.target.checked)} />
                    </div>
                    <div className='DeliveryChalan-search'>
                        <input
                            style={{ width: "10%", padding: "0 2px" }}
                            type="text"
                            name="docuno"
                            placeholder='Doc. No'
                            // ref={searchNameRef}
                            autoComplete="off"
                            className='search-input'
                            onChange={(e) => handleFilterTextChange(e.target.value, e.target.name)}
                            onGridReady={onGridReady}
                        // onKeyDown={handleOnKeyDown}
                        />
                        <FaSearch className="search-icon" />

                        <input
                            style={{ width: "60%", padding: "0 2px" }}
                            type="text"
                            name="actname"
                            ref={searchNameRef}
                            placeholder='Search Customer'
                            autoComplete="off"
                            className='search-input'
                            onChange={(e) => handleFilterTextChange(e.target.value, e.target.name)}
                            onGridReady={onGridReady}
                        // onKeyDown={handleOnKeyDown}
                        />
                        <FaSearch className="search-icon" />
                    </div>
                    <div className='DeliveryChalan-btngrp-btn'>
                        {/* <button style={{width:"50px", fontSize:"12px"}} onClick={() => setEwayModal(true)}>EW.Bill</button> */}
                        <button className='mybtn' onClick={() => navigate(-1)}><AiOutlineClose className="btn-icon" /></button>
                        <button className='mybtn' onClick={handleJsonDownload}><AiOutlineFile className="btn-icon" /></button>
                        <button className="mybtn" onClick={handlePrintSelected}><AiOutlinePrinter className="btn-icon" /></button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className="btn-icon" /></button>

                    </div>
                </div>
                <div className="aag-theme-alpine" style={{ width: "97%", height: "90%", }}
                    tabIndex={0}
                >
                    <AgGridReact
                        ref={gridRef}
                        rowHeight={20}
                        rowData={curdata}
                        columnDefs={columnDefs}
                        rowSelection="single"
                        domLayout="normal"
                        defaultColDef={{ resizable: true, flex: 1 }}
                        getRowStyle={getRowStyle}
                        onKeyDown={handleOnKeyDown}
                    />
                </div>
            </div>
            {ewayModal &&
                <div className="ewaymodal-overlay">
                    <div className="ewaymodal-container">
                        <div className="ewaymodal-header">
                            <h2>EWAY BILL</h2>
                            <button onClick={() => setEwayModal(false)} className="eway-closebtn">X</button>
                        </div>

                        <div className="eway-body">
                            <div className="eway-top">
                                <div className="ewayform-group1">
                                    <label>Ref. No. : </label>
                                    <input type="text" value={ewaydetails.docuno} readonly tabIndex={-1}
                                        onKeyDown={(e) => onEnterkeydown(e, "ewaybillno")} />
                                </div>
                                <div className="ewayform-group1">
                                    <label>Trn. Date : </label>
                                    <input type="text" style={{ textAlign: "left" }} value={ewaydetails.docudate} readonly tabIndex={-1} onKeyDown={(e) => onEnterkeydown(e, "ewaybillno")} />
                                </div>
                                <div className="ewayform-group1">
                                    <label>Delivery At : </label>
                                    <input type="text" value={ewaydetails.actname} style={{ width: "70%" }} readonly tabIndex={-1} onKeyDown={(e) => onEnterkeydown(e, "ewaybillno")} />
                                </div>
                                <div className="ewayform-group1" >
                                    <label>GSTIN: </label>
                                    <input type="text" value={ewaydetails.gstin} style={{ width: "70%" }} readOnly tabIndex={-1} onKeyDown={(e) => onEnterkeydown(e, "ewaybillno")} />
                                </div>
                                <div className="ewayform-group1">
                                    <label>Goods Value: </label>
                                    <input type="text" value={ewaydetails.goodsvalue} readonly tabIndex={-1} onKeyDown={(e) => onEnterkeydown(e, "ewaybillno")} />
                                </div>
                            </div>
                            <div className='eway-bottom'>
                                <div className="ewayform-group1">
                                    <label htmlFor="ewaybillno">e-Way Bill No.</label>
                                    <NumericFormat
                                        autoComplete='off'
                                        name='ewaybillno'
                                        id="ewaybillno"
                                        value={ewaydetails.ewaybillno}
                                        onValueChange={(values) => handleDetailsChange(values.floatValue, 'ewaybillno')}
                                        style={{ width: "40%" }}
                                        maxLength={12}
                                        decimalScale={0}
                                        thousandSeparator={false}
                                        allowNegative={false}
                                        allowLeadingZeros={false}
                                        isNumericString
                                        onKeyDown={(e) => onEnterkeydown(e, "ewaybilldate")}
                                    />
                                </div>

                                <div className="ewayform-group1">
                                    <label htmlFor="ewaybilldate">e-Way Bill Date</label>
                                    <InputMask
                                        id='ewaybilldate'
                                        name='ewaybilldate'
                                        mask="99/99/9999"
                                        autoComplete='off'
                                        placeholder="DD/MM/YYYY"
                                        maskChar=""
                                        value={ewaydetails.ewaybilldate}
                                        onChange={(e) => handleDetailsChange(e.target.value, e.target.name)}
                                        onKeyDown={(e) => onEnterkeydown(e, "ok")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="eway-btngrp">
                            <button id='ok' className="ok-btn" onClick={handleEwaySave} >Save</button>
                            <button onClick={() => setEwayModal(false)} className="cancel-btn" >Close</button>
                        </div>

                    </div>
                </div>

            }
        </div>

    );
};

export default DeliveryChalanLookup;
