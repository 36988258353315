import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { useDispatch } from 'react-redux';
import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import { AiOutlineFilePdf, AiOutlineFileExcel, AiOutlineFileText, AiOutlinePrinter,AiOutlinePlus } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import './TransportLookup.css';

import { myaction } from '../../redux/Mystore';

const TransportLookup = () => {
    const mydispatch=useDispatch()
    const navigate=useNavigate()
    const tableRef = useRef();
    const [initialData, setinitialData] = useState([]);
    const [curdata, setcurdata] = useState([]);
    const [search, setSearch] = useState('');
    
    const fetchdata = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/transport_readall`);
            setinitialData(response.data);
            setcurdata(response.data);
        } catch (error) {
            console.error('There was an error fetching the data!', error);
        }
    };
    
    useEffect(() => {
        fetchdata();
    },[]);

    const handleSearchChange = (event) => {
        const value = event.target.value;
        setSearch(value);
        if (value==="") {
            setcurdata(initialData);    
            return;
        }
        const filtered = initialData.filter(item => item.transportname.toLowerCase().includes(value.toLowerCase()));
        setcurdata(filtered);
    };

    const handleDelete = (data) => {
            Swal.fire({
              title: 'Are you sure?',
              text: data.transportname,
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                confirmDelete(data);
              }
        });
    }

    const confirmDelete = async (data) => {
        const userdata = { 
            transportcode:data.transportcode
        }
        const url=`${process.env.REACT_APP_RAINBOW_URL}/transport_delete`
        try {
            await axios.post(url,userdata);
            fetchdata();
        } catch (error) {
            console.error('There was an error deleting the data!', error);
        }
    };

    const handlePrint = () => {
        window.print();
    };
    
    const handlePDFDownload = () => {
        const doc = new jsPDF();
        doc.autoTable({ html: '#transport-table' });
        doc.save('transport-list.pdf');
    };

    const handleExportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(curdata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Transport");
        XLSX.writeFile(workbook, "data.xlsx");
    };

    const handleExportToJSON = () => {
        const blob = new Blob([JSON.stringify(curdata, null, 2)], { type: 'application/json' });
        saveAs(blob, 'data.json');
    };

    const handleEdit = (data) => {
        mydispatch(myaction.settransportmode(["Edit",data.transportcode]));
        navigate("/installtransport")
    }

    const handleAddNew = () => {
        mydispatch(myaction.settransportmode(["New",0]));
        navigate("/installtransport")
    }

    const merge = (data1='',data2='',data3='') => {
        let str = data1
        if (data2!=='') {
            str += (str==='' ? '' : ', ') + data2
        }
        if (data3!=='') {
            str += (str==='' ? '' : ', ') + data3
        }
        return str;
    }

    useEffect(() => {
        const handleKeyPress = (event) => {
            event = event || window.event;
            if (event.key === 'Escape') {
                navigate("/emptypage")
                mydispatch(myaction.hideMenu(false))
            }
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);


    return (
        <div id="frmlookuptransport" className= 'frmlookuptransport-divmain'>
            <div className="table-container">
                <h3>Transport</h3>
                <div className='searchtext'>
                    <input
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearchChange}
                        className="search-input"
                    />
                    <div className="mybtncontainer">
                        <button className="mybtn" onClick={handlePDFDownload}><AiOutlineFilePdf className="btn-icon" />PDF</button>
                        <button className="mybtn" onClick={handleExportToExcel}><AiOutlineFileExcel className="btn-icon" />Excel</button>
                        <button className="mybtn" onClick={handleExportToJSON}><AiOutlineFileText className="btn-icon" />JSON</button>
                        <button className="mybtn" onClick={handlePrint}><AiOutlinePrinter className="btn-icon"/>Print</button>
                        <button className="mybtn" onClick={handleAddNew}><AiOutlinePlus className='btn-icon'/>Addnew</button>
                    </div>

                </div>

                <div className='lookupTable' >
                    <table className="tabledata" id="transport-table" ref={tableRef}>
                        <thead className='tablehead' >
                            <tr style={{position: 'sticky', top: '-1px'}}>
                                <th style={{backgroundColor:"black"}}
                                    className="col-transportname" >
                                    Name
                                </th>
                                <th style={{backgroundColor:"black"}}
                                    className="col-transportgstin" >
                                    GSTIN 
                                </th>
                                <th style={{backgroundColor:"black"}}
                                    className="col-transportid" >
                                    Trans.ID 
                                </th>
                                <th style={{backgroundColor:"black"}}
                                    className="col-mobile">
                                    Mobile
                                </th>
                                <th style={{backgroundColor:"black"}}
                                    className="col-action">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {curdata.map(data => (
                                <tr className="row-data" key={data.transportcode} onDoubleClick={() => handleEdit(data)}>
                                    <td className="col-transportname" >{data.transportname}</td>
                                    <td className="col-transportgstin" >{data.transportgstin}</td>
                                    <td className="col-transportid" >{data.transportid}</td>
                                    <td className="col-mobile">
                                        <p>{ merge(data.mobile1,data.mobile2,data.mobile3)}</p>
                                    </td>
                                    <td className="col-action">
                                        <div className="col-action-div">
                                            <button onClick={() => handleEdit(data)}>Edit</button>
                                            <button onClick={() => handleDelete(data)}>Delete</button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TransportLookup;
