import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import './ProductMaster.css'

const ProductMaster = () => {
  const myNavigate = useNavigate()
  const [datalist, setdatalist] = useState([])
  const [selectedpdtgroup, setselectedpdtgroup] = useState("");
  const withWeight = false;
  const withBundlePacking = true;
  const withShortName = false;

  const netrateYN = false;
  const wostockYN = false;

  const [netrate, setnetrate] = useState(false);
  const [wostock, setwostock] = useState(false);
  const [uofmdata, setuofmdata] = useState([]);
  const [selecteduofm, setselecteduofm] = useState('');
  const [pdtnameValue, setpdtnameValue] = useState('');
  const [casecontentvalue, setcasecontentvalue] = useState('');
  const [bdlecontentvalue, setbdlecontentvalue] = useState('');
  const [ratepervalue, setratepervalue] = useState('');
  const [serialnovalue, setserialnovalue] = useState('');

  const pdtnameRef = useRef(null);
  const pdtuofmRef = useRef(null);
  const pdtcasecontentRef = useRef(null);
  const pdtbdlecontentRef = useRef(null);
  const pdtrateperRef = useRef(null);
  const pdtserialRef = useRef(null);
  const pdtHSNRef = useRef(null);
  let url = "";
  const inputgroupnameRef = useRef()

  const curmode = useSelector(function (data) {
    return data.pdtmstrmode;
  })

  useEffect(() => {

    const fetchuofmdata = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_RAINBOW_URL}/readuofm`)
        setuofmdata(response.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    const fetchpdtgrpdata = async () => {
      axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productgroup_readall`)
        .then(response => {
          const options = response.data.map(data => ({ value: data.pdtgroupcode, label: data.pdtgroupname }));
          setdatalist(options)
          console.log(options);
        })
        .catch(error => {
          console.error('Error fetching last record:', error);
        });
    };

    const fetchdata = async (e) => {
      if (curmode[0] === "Edit") {
        const data = { pdtcode: curmode[1] };
        const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readbypdtcode`, data)
        if (response.data.length > 0) {
          console.log(response.data[0].pdtgroupcode);
          setserialnovalue(response.data[0].pdtserial);
          pdtnameRef.current.value = response.data[0].pdtname;
          setpdtnameValue(response.data[0].pdtname);
          pdtuofmRef.current.value = response.data[0].uofm;
          setselecteduofm(response.data[0].uofm);
          setratepervalue(response.data[0].rateper);
          setcasecontentvalue(response.data[0].casecontent);
          setbdlecontentvalue(response.data[0].bdlecontent);
          pdtHSNRef.current.value = response.data[0].hsn;
          setnetrate(response.data[0].netrate ? true : false);
          setwostock(response.data[0].wostock ? true : false);
          setselectedpdtgroup({
            value: response.data[0].pdtgroupcode,
            label: response.data[0].pdtgroupname
          });

        }
      }
    };
    const fetchlastserial = async () => {
      pdtserialRef.current.value = 1;
      axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_lastserial`)
        .then(response => {
          if (response.data) {
            pdtserialRef.current.value = response.data.pdtserial + 1;
            setserialnovalue(response.data.pdtserial + 1);
          }
        })
        .catch(error => {
          console.error('Error fetching last record:', error);
        });
    }


    fetchuofmdata();
    fetchpdtgrpdata();
    fetchdata()
    if (curmode[0] === "New") {
      fetchlastserial();
    }

  }, [curmode]);


  const handeleuofmChanged = (event) => {
    setselecteduofm(event.target.value);
  }

  const handlepdtnamechange = (event) => {
    const { value } = event.target;
    console.log(value)
    setpdtnameValue(toProperCase(value));
  };


  // Function to convert a string to proper case
  const toProperCase = (str) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  const handlecasecontentChange = (event) => {
    let { value } = event.target;
    if (!(/^\d{0,4}$/.test(value))) {
      return;
    }
    value = parseInt(value);
    setcasecontentvalue(value);
  }

  const handlebdlecontentChange = (event) => {
    let { value } = event.target;
    if (!(/^\d{0,4}$/.test(value))) {
      return;
    }
    value = parseInt(value);
    setbdlecontentvalue(value);
  }

  const handlerateperChange = (event) => {
    let { value } = event.target;
    if (!(/^\d{0,4}$/.test(value))) {
      return;
    }
    value = parseInt(value);
    setratepervalue(value);
  }
   
  const handlenetrateChange = (event) =>{
      setnetrate(event.target.checked);
  }

  const handlewostockChange = (event) =>{
    setwostock(event.target.checked);
  }

  const handleserialChange = (event) => {
    let { value } = event.target;
    if (!(/^\d{0,4}$/.test(value))) {
      return;
    }
    value = parseInt(value);
    setserialnovalue(value);
  }



  const handleAvoidDotKeyPress = (event) => {
    // Get the key code of the pressed key
    const keyCode = event.keyCode || event.which;
    // Check if the key pressed is a decimal point
    if (keyCode === 46 || keyCode === 110 || keyCode === 190) {
      // Prevent default action (i.e., typing the decimal point)
      event.preventDefault();
    }
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderRadius: '2px',
      fontSize: "18px",
      fontWeight: 600,
      borderColor: state.isFocused ? '#0366d6' : '#ccc',
      boxShadow: state.isFocused ? '0 0 0 2px rgba(3, 102, 214, 0.3)' : 'none',
      '&:hover': {
        borderColor: state.isFocused ? '#0366d6' : '#aaa',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#0366d6' : state.isFocused ? '#f1f8ff' : 'white',
      color: state.isSelected ? 'white' : '#333',
      '&:hover': {
        backgroundColor: '#0366d6',
        color: 'white',
      },
    }),
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const pdtname = pdtnameRef.current.value;
    const pdtuofm = pdtuofmRef.current.value;
    let pdtcasecontent = parseInt(pdtcasecontentRef.current.value);
    let pdtbdlecontent = parseInt(pdtbdlecontentRef.current.value);
    let pdtrateper = pdtrateperRef.current.value;
       
        const pdtserial = parseInt(pdtserialRef.current.value);
    let pdtHSN = pdtHSNRef.current.value;
    const pdtgroupcode = parseInt(selectedpdtgroup.value);
    
    if (pdtname.trim() === "") {
      alert("Product Name Should be Given");
      pdtnameRef.current.focus();
      return;
    }
    if (pdtuofm.trim() === "") {
      alert("Product UOFM Should be Given");
      pdtuofmRef.current.focus();
      return;
    }

    if (isNaN(pdtbdlecontent)) {
      pdtbdlecontent = 0
    }

    if (isNaN(pdtcasecontent)) {
      pdtcasecontent = 0
    }

    if ((isNaN(pdtrateper)) || (pdtrateper === 0)) {
      pdtrateper = 1
    }

    if ((pdtcasecontent > 9999) || (pdtbdlecontent > 9999)) {
      alert("Case Content or Bundle Content Should be Given")
      pdtcasecontent.current.focus();
      return;
    }


    if (pdtserial > 9999) {
      alert("Serial No. Should be given..");
      pdtserialRef.current.focus();
      return;
    }


    if (!pdtgroupcode > 0) {
      alert("Product Group Should be Given");
      inputgroupnameRef.current.focus();
      return;
    }

    try {
      const data = {
        pdtserial: pdtserial,
        pdtcode: (curmode[0] === "New" ? 0 : curmode[1])
      };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtserial4pdtcode`, data);
      if (response.data.length > 0) {
        alert('Serial already Given ');
        pdtserialRef.current.focus();
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message, etc.)
    }

    try {
      const data = {
        pdtname: pdtname,
        pdtcode: (curmode[0] === "New" ? 0 : curmode[1])
      };
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_readpdtname`, data);
      if (response.data.length > 0) {
        alert('Product Name already Given ');
        pdtnameRef.current.focus();
        return;
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }

    try {
      const data = {

        pdtserial: pdtserial,
        pdtcode: (curmode[0] === "New" ? pdtserial : curmode[1]),
        pdtname: pdtname.trim(),
        uofm: pdtuofm.trim(),
        casecontent: pdtcasecontent,
        bdlecontent: pdtbdlecontent,
        rateper: pdtrateper,
        netrate: netrate ? 1 : 0,
        wostock: wostock ? 1 : 0,
        hsn: pdtHSN.trim(),
        pdtgroupcode: pdtgroupcode,
        shortname: '',
        rate: 0,
        gstrate: 0,
        cess: 0,
        cessrate: 0,
      };

      url = `${process.env.REACT_APP_RAINBOW_URL}/productmaster_create`
      if (curmode[0] === "Edit") {
        url = `${process.env.REACT_APP_RAINBOW_URL}/productmaster_update`
      }
      await axios.post(url, data);


      if (curmode[0] === "Edit") {
        myNavigate("/listproductmaster")
        return;
      }

      //  fetchlastserial();
      pdtserialRef.current.value = parseInt(pdtserialRef.current.value) + 1;

      pdtnameRef.current.value = "";
      setpdtnameValue('');
      setserialnovalue(pdtserialRef.current.value);



      pdtnameRef.current.focus();

      //myNavigate("/emptypage")

      // Handle success (redirect, show success message, etc.)
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error (show error message, etc.)
    }
  };






  const fetchlastserial = async () => {
    pdtserialRef.current.value = 1;
    axios.get(`${process.env.REACT_APP_RAINBOW_URL}/productmaster_lastserial`)
      .then(response => {
        if (response.data) {
          pdtserialRef.current.value = response.data.pdtserial + 1;
        }
      })
      .catch(error => {
        console.error('Error fetching last record:', error);
      });
  }


  useEffect(() => {
    if (curmode[0] === "New") {
      fetchlastserial();
    }
  }, [curmode]);

  useEffect(() => {
    pdtnameRef.current.focus();
  }, []);

  const defaultEnterRef = useRef(null)
  const defaultEscapeRef = useRef(null)

  useEffect(() => {
    const handleKeyPress = (event) => {
      event = event || window.event;
      if ((event.key === 'Enter') || (event.ctrlKey && event.key === 'a')) {
        defaultEnterRef.current.click();
      }
      if (event.key === 'Escape') {
        defaultEscapeRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };

  }, []);

  return (
    <div>
      <div className="divmain">
        <h1>Product Master</h1>
        <div className="prodet">
          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Item Name</label>
            <input
              ref={pdtnameRef}
              value={pdtnameValue}
              onChange={handlepdtnamechange}
              className='prodetailinp1'
              type="text"
              maxLength="75" />
          </div>

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">UOFM</label>
            <div className='prodetnumb'>
              <select onChange={handeleuofmChanged} value={selecteduofm} ref={pdtuofmRef}>
                {uofmdata.map(data => (
                  <option key={data.id} value={data.uofm}>{data.uofm}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Case Content</label>
            <input
              ref={pdtcasecontentRef}
              onKeyPress={handleAvoidDotKeyPress}
              className='prodetnumb'
              type="number"
              onChange={handlecasecontentChange}
              maxLength="4"
              name="casecontent"
              value={casecontentvalue}
              min="0" />
            <input className='prodetnumb' type="text" value={selecteduofm} tabIndex="-1" readOnly />
          </div>

          {withBundlePacking &&
            <div className="divproductdetails">
              <label className='prodetlabel' htmlFor="">Bdle Content</label>
              <input className='prodetnumb' type="number" onChange={handlebdlecontentChange} value={bdlecontentvalue} maxLength="4" min="0" ref={pdtbdlecontentRef} name="bdlecontent" tabIndex="-1" />
              <input className='prodetnumb' type="text" value={selecteduofm} tabIndex="-1" readOnly />
            </div>
          }

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Rate Per</label>
            <input className='prodetnumb' type="number" maxLength="4" min="0" value={ratepervalue} onChange={handlerateperChange} ref={pdtrateperRef} />
            <input className='prodetnumb' type="text" value={selecteduofm} tabIndex="-1" readOnly />
            { netrateYN && 
              <div>
                <label className='prodetlabel2' htmlFor="">Net Rate</label>
                <input type="checkbox"  tabIndex="-1" checked={ netrate } onChange={handlenetrateChange} />
              </div>
            }       
            { wostockYN && 
              <div>
                <label className='prodetlabel3' htmlFor="">With-out Stock</label>
                <input type="checkbox" tabIndex="-1" checked={ wostock }  onChange={handlewostockChange} />
              </div>
            }
          </div>

          {withWeight &&
            <div className="divproductdetails">
              <label className='prodetlabel' htmlFor="">Weight</label>
              <input type="number" className='prodetnumb' step="0.001" />
              <label className='prodetlabel2' htmlFor="">Kgs. Per</label>
              <input className='prodetnumb' type="number" step="0.0001" min="0" />
              <input className='prodetnumb' type="text" value={selecteduofm} tabIndex="-1" readOnly />
            </div>}

          {withShortName &&
            <div className="divproductdetails">
              <label className='prodetlabel' htmlFor="">Short Name</label>
              <input type="text" className='prodetailinp1' maxLength="20" />
            </div>
          }

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Serial</label>
            <input type="number" className='prodetnumb' maxLength="4" value={serialnovalue} onChange={handleserialChange} ref={pdtserialRef} />
          </div>

          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">HSN/SAC</label>
            <input type="text" className='prodetailinp2' maxLength="8" ref={pdtHSNRef} tabIndex="-1" />
          </div>
          <div className="divproductdetails">
            <label className='prodetlabel' htmlFor="">Group Name</label>
            <div className='selectoption'>
              <Select
                className='prodetailinp1 pdtgroupSelect'
                ref={inputgroupnameRef}
                options={datalist}
                value={selectedpdtgroup}
                onChange={setselectedpdtgroup}
                styles={customStyles}
              />
            </div>
          </div>
          <div className="divproductdetailsbtns">
            <button className='prodetbtn' onClick={() => myNavigate("/listproductmaster")} ref={defaultEscapeRef} >Cancel</button>
            <button className='prodetbtn' onClick={handleSubmit} ref={defaultEnterRef} >Save</button>
          </div>
        </div>
      </div>
    </div>
  )
}
export default ProductMaster
