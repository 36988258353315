import React, { useState } from "react";
import "./Navbar.css";
import { FaHome, FaUpload, FaWhatsapp, FaCog, FaPhone, FaTruck, FaDownload } from "react-icons/fa";
import { Link, } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { myaction } from "../../redux/Mystore";

const Navbar = () => {

  const [whatsapp, setWhatsapp] = useState(false);
  const [install, setInstall] = useState(false);
  const [challan, setChallan] = useState(false);
  const [settings, setSettings] = useState(false);
  const [admincontrol, setAdminControl] = useState(false);


  let hideMenu = useSelector((xdata) => {
    return xdata.hidemenu;
  })

  const isadmin = useSelector((xdata) => {
    return xdata.isAdmin;
  })

  const mydispatch = useDispatch()

  function sethidemenu(params) {
    mydispatch(myaction.hideMenu(true))
  }

  function openwhatsappmodel(params) {
    setWhatsapp(!whatsapp)
  }

  function openchallanmodel(params) {
    setChallan(!challan)
  }
  function openinstallmodel(params) {
    setInstall(!install)
  }
  function opensettingsmodel(params) {
    setSettings(!settings)
  }
  function openadminmodel(params) {
    setAdminControl(!admincontrol)
  }

  function hidedata(params) {
    setWhatsapp(false)
    setChallan(false)
    setInstall(false)
    setSettings(false)
    setAdminControl(false)
  }

  return (
    <>
      <div class={hideMenu ? "navbar-hide" : "navbar"}>
        <ul class="navbar-item hide">

          <li class="active"><FaHome />&nbsp; Home</li>


          <Link to="/annualreturn" className="linkcom" onClick={sethidemenu}>
            <li>GSTR9 HSN</li>
          </Link>

          <Link to="/gstr1hsn" className="linkcom" onClick={sethidemenu}>
            <li>GSTR1 HSN</li>
          </Link>

          <Link to="/gstr2b" className="linkcom" onClick={sethidemenu}>
            <li>GSTR2B</li>
          </Link>

          {isadmin &&
            <Link to="/gstr1view" className="linkcom" onClick={sethidemenu}>
              <li> GSTR1 View</li>
            </Link>
          }
          {/* {isadmin && */}
            <li className="dropdown" onClick={openchallanmodel} onMouseLeave={hidedata}><FaTruck />&nbsp; Delivery Entry
              {challan && (<ul className="droplist1">
                <Link to="/listdeliverychalan-branchtransfer" className="linkcom1" onClick={sethidemenu}>
                  <li>Branch Tranfer</li>
                </Link>
                <Link to="/listdeliverychalan-jobbased" className="linkcom1" onClick={sethidemenu}>
                  <li>Job Based</li>
                </Link>
              </ul>)}
            </li>
          {/* } */}
  {isadmin &&
          <li className="dropdown" onClick={openinstallmodel} onMouseLeave={hidedata}><FaDownload />&nbsp; Install
            {install && (<ul className="droplist1">
              <Link to="/listaccledger_customer" className="linkcom1" onClick={sethidemenu}><li>Customer Ledger Name</li></Link>
              <Link to="/listaccledger_supplier" className="linkcom1" onClick={sethidemenu}><li>Supplier Ledger Name</li></Link>
              <Link to="/listproductmaster" className="linkcom1" onClick={sethidemenu}><li>Product Master</li></Link>
              <Link to="/listproductgroup" className="linkcom1" onClick={sethidemenu}><li>Product Group</li></Link>
              <Link to="/listtransport" className="linkcom1" onClick={sethidemenu}><li>Transport</li></Link>
              <Link to="/liststockplace" className="linkcom1" onClick={sethidemenu}><li>Stock Place</li></Link>
              <Link to="/listcompany" className="linkcom1" onClick={sethidemenu}><li>Company</li></Link>
              <Link to="/listpricelist" className="linkcom1" onClick={sethidemenu}><li>Price List</li></Link>
              <Link to="/stockentry" className="linkcom1" onClick={sethidemenu}><li>Opening Stock</li></Link>
            </ul>)}
          </li>
}

          {isadmin &&
            <li className="dropdown" onClick={opensettingsmodel} onMouseLeave={hidedata}><FaCog />&nbsp; Settings
              {settings && (<ul className="droplist1">
                <Link to="/flashnews" className="linkcom1" onClick={sethidemenu}>
                  <li> Flash News</li>
                </Link>
                <Link to="/printsettings" className="linkcom1" onClick={sethidemenu}>
                  <li> Print Settings</li>
                </Link>
              </ul>)}
            </li>
          }
          {(isadmin) &&
            <li className="dropdown" onClick={openwhatsappmodel} onMouseLeave={hidedata}><FaWhatsapp />&nbsp; Whatsapp
              {whatsapp && (<ul className="droplist1">
                <Link to="/uploadpdf" className="linkcom1" onClick={sethidemenu}>
                  <li><FaUpload />&nbsp; Upload PDF</li>
                </Link>
                <Link to="/smssettings" className="linkcom1" onClick={sethidemenu}><li><FaCog />&nbsp; Settings</li></Link>
              </ul>)}
            </li>
          }

          <Link to="/contactus" className="linkcom" onClick={sethidemenu}>
            <li> <FaPhone />&nbsp; Contact Us</li>
          </Link>


          {isadmin &&
            <li className="dropdown" onClick={openadminmodel} onMouseLeave={hidedata}> Admin Panel
              {admincontrol && (<ul className="droplist1">
                <Link to="/license" className="linkcom1" onClick={sethidemenu}>
                  <li> Activation</li>
                </Link>
                <Link to="/viewusers" className="linkcom1" onClick={sethidemenu}>
                  <li> Users List</li>
                </Link>
              </ul>)}
            </li>
          }

        </ul>
      </div>
    </>
  );
};

export default Navbar;
