import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import "./flash.css"

const Flashnews = () => {

  const configArray = [{ id_name: "Flash News", id_value: "" },
  { id_name: "GSTR9-Flash News", id_value: "" },
  { id_name: "GSTR9-Youtube URL", id_value: "" },
  { id_name: "GSTR2B-Flash News", id_value: "" },
  { id_name: "GSTR2B-Youtube URL", id_value: "" }];

  const [formValue, setformValue] = useState(configArray)

  const updateData = (updates) => {
    const updatedData = configArray.map((item) => {
      const update = updates.find((u) => u.id_name === item.id_name);
      return update ? { ...item, id_value: update.id_value } : item; 
    });
    setformValue(updatedData);
  };


  const fetchConfigValue = async () => {
    try {
      const data = { configArray }
      const response = await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/readConfigData`, data);
      if (response.data.length > 0) {
        updateData(response.data)
      }
    } catch (error) {
      console.error('Error fetching config value:', error);
    }
  };

  useEffect(() => {
    fetchConfigValue();
  }, []);

  const myNavigate = useNavigate()

  const firstinputRef = useRef(null)
  const urlRef = useRef(null);

  useEffect(() => {
    firstinputRef.current.focus();
  }, []);

  const defaultEnterRef = useRef(null)
  const defaultEscapeRef = useRef(null)

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        defaultEnterRef.current.click();
      }
      if (event.key === 'Escape') {
        defaultEscapeRef.current.click();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);


  const updateConfigValue = async (event) => {
    event.preventDefault();

    try {
      const data = { formValue };
      await axios.post(`${process.env.REACT_APP_RAINBOW_URL}/updateConfigData`, data);
    } catch (error) {
      console.error('Error updating config value:', error);
    }
    myNavigate("/emptypage")
    return;
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target; // Extract name (id_name) and value from the input
    const updatedData = formValue.map((item) =>
      item.id_name === name ? { ...item, id_value: value } : item
    );
    setformValue(updatedData);
  };

  return (
    <>
      <div className="mypageflashnews">
        <div className='mypagewindow'>
          <div className='mainflashnews'>
            <h1>Flash News</h1>
            <textarea id="Flash News" cols="30" rows="10"
              ref={firstinputRef}
              onChange={handleInputChange}
              name={formValue[0].id_name}
              value={formValue[0].id_value}
              placeholder="Enter new value..."></textarea>
          </div>
          <div className='flashnews-container'>
            <div className='flashnews-gstr'>
              <div className='flashnews-grp'>
                <label htmlFor="">Flash News(GSTR9)</label>
                <textarea id="" cols="30" rows="10"
                  onChange={handleInputChange}
                  name={formValue[1].id_name}
                  value={formValue[1].id_value}
                  placeholder="Enter new value..."></textarea>

              </div>
              <div className='flashnews-grp'>
                <label htmlFor="">Youtube Url(GSTR9)</label>
                <input type="text"

                  id="url"
                  ref={urlRef}
                  onChange={handleInputChange}
                  name={formValue[2].id_name}
                  value={formValue[2].id_value}
                  placeholder="Enter URL..."></input>
              </div>
            </div>
            <div className='flashnews-gstr'>
              <div className='flashnews-grp'>
                <label htmlFor="">Flash News(GSTR2B)</label>
                <textarea id="" cols="30" rows="10"
                  onChange={handleInputChange}
                  name={formValue[3].id_name}
                  value={formValue[3].id_value}
                  placeholder="Enter new value..."></textarea>

              </div>
              <div className='flashnews-grp'>
                <label htmlFor="">Youtube Url(GSTR2B)</label>
                <input type="text"
                  id="url"
                  ref={urlRef}
                  onChange={handleInputChange}
                  name={formValue[4].id_name}
                  value={formValue[4].id_value}
                  placeholder="Enter URL..."></input>
              </div>
            </div>
            <div className="flashnews-btngrp">
              <button onClick={() => myNavigate("/emptypage")} ref={defaultEscapeRef} >Go back</button>
              <button onClick={updateConfigValue} ref={defaultEnterRef}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Flashnews;